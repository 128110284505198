import { useDispatch } from "react-redux";
import { setDrawer, DrawerEnum } from "../../store/toolBuilderSlice";
import { useState } from "react";
import { AllBlockTypes } from "../../../../../../../../shared/src/types/blocks";
import useUpdateField from "../../useUpdateField";

export const useBlockModalDrawer = (
  nodeId: string,
  blockType: AllBlockTypes
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const updateField = useUpdateField(nodeId);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleActionSelect = (integration: { type: string; name: string }) => {
    updateField(integration.type, "settings.selectedIntegrationType");
    dispatch(
      setDrawer({
        type: DrawerEnum.node,
        nodeId,
        blockType,
        initialData: {
          selectedIntegrationType: integration.type,
          integrationName: integration.name
        }
      })
    );
    closeModal();
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    handleActionSelect
  };
};
