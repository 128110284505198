import { useEffect, useState } from "react";
import { createFrontendClient } from "@pipedream/sdk";
import { useGenerateTokenMutation } from "../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { ENVIRONMENT } from "@toolflow/shared";
import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";

export const usePipedreamClient = () => {
  const [pdClient, setPdClient] = useState<$TSAllowedAny>(null);
  const [generateToken] = useGenerateTokenMutation();
  const user = useGetCurrentUser();

  useEffect(() => {
    const initializePipedreamClient = async () => {
      if (!user?._id) {
        setPdClient(null);
        return;
      }
      try {
        const tokenResponse = await generateToken().unwrap();

        const client = createFrontendClient({
          environment: ENVIRONMENT.DEVELOPMENT,
          externalUserId: user._id,
          tokenCallback: async () => ({
            token: tokenResponse.token,
            expires_at: tokenResponse.expires_at
          })
        });
        setPdClient(client);
      } catch (error) {
        console.error("Failed to initialize Pipedream client:", error);
      }
    };

    initializePipedreamClient();
  }, [generateToken, user]);

  return pdClient;
};
