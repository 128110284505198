import { CircularProgress } from "@mui/material";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import { GroupAccordionHeader } from "../../../../builder/toolBuilder/common/fieldsByType/components/fieldsGroupComponent/GroupAccordionHeader";

const LoadingToolDetails = () => {
  const { tool } = useToolContext();
  return (
    <GroupAccordionHeader
      titleVariant="subtitle2"
      titleClassName="grey"
      title={tool.name}
      actionIcon={<CircularProgress size={12} className="grey" />}
    />
  );
};

export default LoadingToolDetails;
