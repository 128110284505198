import { FormControl, MenuItem, Select } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicSelectFieldType,
  type SelectOption
} from "@toolflow/shared";
import { isString } from "lodash";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";

export default function DynamicSelectField() {
  const { id, data, field, getFieldValue, updateField } =
    useFieldsByTypeContext<DynamicSelectFieldType>();
  const { config, label, onChange } = field;
  const {
    fieldKey,
    typeKey,
    defaultValue = "",
    filterAvailableFields
  } = config;

  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const options = useGetConfigFieldValue<SelectOption[]>(config.options);

  function handleFieldValueUpdate(value: $TSAllowedAny) {
    updateField(value, fieldKey);
    onChange?.(value, data, updateField);
  }

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={config}
        fieldValue={fieldValue}
        addInputType={CustomToolInputFieldTypesEnum.SELECT}
        filterOptions={filterAvailableFields}
        onTypeChangeCallback={(type) => {
          handleFieldValueUpdate(
            type === DynamicFieldType.Dynamic
              ? CREATE_USER_INPUT_LABEL
              : defaultValue
          );
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <Select
            fullWidth
            size="small"
            value={fieldValue}
            disabled={disabled}
            className="bg-color-white"
            onChange={(event) => handleFieldValueUpdate(event.target.value)}
          >
            {options.map((option) => {
              if (isString(option)) {
                return (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
