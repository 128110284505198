import { type PipedreamActionFieldType } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import SelectInputInner from "../../components/SelectInputInner";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";
import useSlackFieldConfigs from "./useUserIntegrationFieldConfigs";

export function PipedreamActionField() {
  const { id, field, getFieldValue } =
    useFieldsByTypeContext<PipedreamActionFieldType>();
  const options = useSlackFieldConfigs();

  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);

  return (
    <SelectInputInner
      id={id}
      label={field.label}
      subtitle={subtitle}
      disabled={disabled}
      options={options}
      value={getFieldValue(fieldKey, "")}
      fieldKey={fieldKey}
      callback={field.onChange}
    />
  );
}
