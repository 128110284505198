import {
  type BlockSettingsBase,
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type PipedreamFieldType
} from "@toolflow/shared";
import styles from "./pipedreamField.module.css";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import useUpdateField from "../../../../useUpdateField";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import { FormControl, MenuItem, Select } from "@mui/material";
import { CSSProperties } from "react";
import InputListComponent from "../../../../../../../tools/components/inputs/InputListComponent";

export function PipedreamSelectField(props: $TSAllowedAny) {
  const baseStyles: CSSProperties = {
    gridArea: "control"
  };

  const { onChange, prop, value } = useFormFieldContext();
  const { options, inputId, required } = props;
  const { id, field, data } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const updateField = useUpdateField(id);
  const availableFields = useToolInputFieldsAndAvailableFields(id);
  const { getProps } = useCustomize();

  const fieldKey = prop?.name || field.config.fieldKey;
  const typeKey = `${fieldKey}_type`;
  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue =
    typeof value === "object" ? value?.value || "" : value || "";

  const handleSelectChange = (newValue: string) => {
    updateField(newValue, fieldKey);
    onChange(newValue);
  };

  const handleListChange = (newValues: string[]) => {
    updateField(newValues, fieldKey);
    onChange(newValues);
  };

  const handleTypeChange = (newType: DynamicFieldType) => {
    updateField(newType, typeKey);
    const defaultValue =
      newType === DynamicFieldType.Dynamic ? CREATE_USER_INPUT_LABEL : "";
    handleSelectChange(defaultValue);
  };

  const renderSelectContent = () => {
    const selectProps = {
      fullWidth: true,
      size: "small" as const,
      value: fieldValue,
      onChange: (e: $TSAllowedAny) => handleSelectChange(e.target.value),
      className: "nowheel nodrag nopan"
    };

    if (prop.type === "string[]") {
      return (
        <InputListComponent
          valuesList={value}
          handleChange={handleListChange}
        />
      );
    } else {
      if (dynamicFieldValue === DynamicFieldType.Dynamic) {
        return (
          <Select {...selectProps}>
            <MenuItem value={CREATE_USER_INPUT_LABEL}>
              {CREATE_USER_INPUT_LABEL}
            </MenuItem>
            {availableFields.map((availableField) => (
              <MenuItem
                key={availableField.name}
                value={availableField.name}
                className="capitalize"
              >
                {availableField.config?.label || availableField.name}
              </MenuItem>
            ))}
          </Select>
        );
      }
    }

    return (
      <FormControl fullWidth>
        <Select {...selectProps} id={inputId} required={required}>
          {options?.map((option: { label: string; value: string }) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={prop?.label || ""}
        configs={{
          fieldKey,
          typeKey,
          forceDynamic: false
        }}
        fieldValue={fieldValue}
        addInputType={CustomToolInputFieldTypesEnum.SELECT}
        onTypeChangeCallback={handleTypeChange}
      >
        <div className={styles["select-wrapper"]}>{renderSelectContent()}</div>
      </DynamicFieldWrapper>
    </div>
  );
}
