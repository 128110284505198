import { FormControl, Stack, Typography } from "@mui/material";
import {
  type WordReplaceDict,
  type WordReplaceFieldProps
} from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import ReplaceWordsForm from "../../../../settings/deepgram/ReplaceWordsForm";
import styles from "../../components/dynamicFieldWrapper/dynamicFieldWrapper.module.css";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";

const cx = parsedClassNames.bind(styles);

export function WordReplaceField({ field }: WordReplaceFieldProps) {
  const { getFieldValue, updateField } = useFieldsByTypeContext();
  const { label, config } = field;
  const { fieldKey, defaultValue = {} } = config;

  const fieldValue = getFieldValue<WordReplaceDict>(fieldKey, defaultValue);
  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);

  if (hidden) {
    return null;
  }

  return (
    <FormControl margin="normal" fullWidth>
      <div className={cx("p-16px border-radius-8px", "container")}>
        <Stack spacing={1}>
          <div>
            <Typography variant="subtitle1" fontWeight="500">
              {label}
            </Typography>
            {subtitle && (
              <Typography variant="caption" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </div>
          <ReplaceWordsForm
            handleChange={(value) => {
              updateField(value, fieldKey);
            }}
            wordsToReplace={fieldValue}
          />
        </Stack>
      </div>
    </FormControl>
  );
}
