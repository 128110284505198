import { DialogTitle, DialogContent } from "@mui/material";
import UserIntegrationSettings from "../../../../profile/pages/settings/UserIntegrationSettings/UserIntegrationSettings";

interface UserIntegrationModalProps {
  open: boolean;
  onClose: () => void;
  onActionSelect: (action: { type: string; name: string }) => void;
}

const UserIntegrationModal = ({
  onClose,
  onActionSelect
}: UserIntegrationModalProps) => {
  const handleIntegrationSelect = (integration: {
    type: string;
    name: string;
  }) => {
    onActionSelect(integration);
    onClose();
  };

  return (
    <>
      <DialogTitle>Select Integration</DialogTitle>
      <DialogContent>
        <UserIntegrationSettings onSelect={handleIntegrationSelect} />
      </DialogContent>
    </>
  );
};

export default UserIntegrationModal;
