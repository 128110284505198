import { ElementType } from "react";
import { FieldsConfig } from "../fields";
import { TBlock } from "../models";
import { CopyableField } from "../outputs";
import { DecimalString } from "../primitives";
import { BlockConfigFunctions } from "./blockFunctionTypes";
import { BlockDataBase } from "./blocksBaseTypes";
import { AllBlockTypes } from "./blockTypes";
import { $TSAllowedAny } from "../types";

export const VERSION_1_VALUE: DecimalString = "1.0";
export const VERSION_2_VALUE: DecimalString = "2.0";
export const ALL_VERSIONS = [VERSION_1_VALUE, VERSION_2_VALUE];

export type DraggableItem<T extends BlockDataBase = BlockDataBase> = Omit<
  TBlock,
  "id" | "position" | "data" | "parentNode"
> & {
  data: T;
};

export interface BlockConfig {
  fields: FieldsConfig;
  draggableItem: DraggableItem;
  blockPaperLabel: string;
  modalComponent?: React.ComponentType<$TSAllowedAny>;
  nonTestable?: boolean;
  outputType?: CopyableField;
  individualTool?: boolean;
}

export interface BlockConfigFE extends BlockConfig, BlockConfigFunctions {
  icon: ElementType;
}

export type VersionedBlockConfig<T extends BlockConfig = BlockConfig> = Record<
  DecimalString,
  T
>;

export type AllVersionedBlockConfigs<T extends BlockConfig = BlockConfig> =
  Record<AllBlockTypes, VersionedBlockConfig<T>>;
