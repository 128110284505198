import type { PromptInputV1Type } from "@toolflow/shared";
import PromptEditor from "../../../../../promptEditor/PromptEditor";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import InputLabelWrapper from "../../../components/InputLabelWrapper";
import useGetConfigFieldValue from "../../../helpers/useGetConfigFieldValue";

export function PromptInputV1() {
  const { field, id, getFieldValue, updateField } =
    useFieldsByTypeContext<PromptInputV1Type>();
  const { label, subtitle } = field;
  const { prependKey, fieldKey, minRows, maxRows } = field.config;

  const subtitleText = useGetConfigFieldValue<string>(subtitle);

  return (
    <InputLabelWrapper label={label} subtitle={subtitleText}>
      <PromptEditor
        id={id}
        prompt={getFieldValue(fieldKey, "")}
        maxRows={maxRows}
        minRows={minRows}
        handleUpdatePrompt={(newValue) => {
          const key = prependKey ? [prependKey, fieldKey].join(".") : fieldKey;
          updateField(newValue, key);
        }}
      />
    </InputLabelWrapper>
  );
}
