import { ReactNode } from "react";
import { DrawerEnum } from "../../../store/toolBuilderSlice";
import ToolInputDrawer from "./inputs/ToolInputDrawer";
import ToolOutputDrawer from "./outputs/ToolOutputDrawer";
import { RootState } from "../../../../../../../stores/store";
import { useSelector } from "react-redux";
import ToolInfoContent from "../../../nodeSelector/ToolInfoContent";
import NodeInfoContent from "./nodeInfo/NodeInfoContent";
import GenerateSchemaDrawer from "./generateSchema/GenerateSchemaDrawer";
import BlockModalWrapper from "../../../blocks/components/BlockModalWrapper";
import { getBlockConfigFE } from "../../../blocks/configs/blockConfig";

// Drawer component map
const drawerComponents: Record<DrawerEnum, ReactNode> = {
  [DrawerEnum.toolInfo]: <ToolInfoContent />,
  [DrawerEnum.inputs]: <ToolInputDrawer />,
  [DrawerEnum.outputs]: <ToolOutputDrawer />,
  [DrawerEnum.node]: <NodeInfoContent />,
  [DrawerEnum.generateSchema]: <GenerateSchemaDrawer />
};

const DrawerContent = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);

  if (!drawer) return null;

  if (
    drawer.type === DrawerEnum.node &&
    drawer.blockType === "UserIntegration" &&
    !drawer.initialData
  ) {
    const blockConfig = getBlockConfigFE(drawer.blockType);
    if (blockConfig.modalComponent) {
      return (
        <>
          {drawerComponents[drawer.type]}
          <BlockModalWrapper
            nodeId={drawer.nodeId}
            blockType={drawer.blockType}
            ModalComponent={blockConfig.modalComponent}
          />
        </>
      );
    }
  }

  return drawerComponents[drawer.type];
};

export default DrawerContent;
