import { Box } from "@mui/material";
import {
  type FieldsByTypeProps,
  type FieldsGroupConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import { isFunction } from "lodash";
import { FieldsByTypeProvider } from "../../FieldsByTypeContext";
import { fieldTypeComponents } from "../../fieldTypeComponents";
import GroupAccordion from "./GroupAccordion";

function GroupedFields({
  field,
  configs
}: FieldsByTypeProps & {
  field: FieldTypeConfigs;
}) {
  const { data, id, chipsToUse, updateField } = configs;
  const Component = fieldTypeComponents[field.type];
  if (!Component) return null;
  return (
    <Box sx={{ marginInline: 1 }}>
      <FieldsByTypeProvider
        id={id}
        data={data}
        field={field}
        chipsToUse={chipsToUse}
        updateField={updateField}
      >
        <Component field={field} settings={data} id={id} />
      </FieldsByTypeProvider>
    </Box>
  );
}

export default function GroupedFieldWrapper({
  group,
  configs
}: FieldsByTypeProps & {
  group: FieldsGroupConfig;
}) {
  const fieldTypeConfigs = isFunction(group.fields)
    ? group.fields(configs.data)
    : group.fields;

  return (
    <GroupAccordion title={group.label} subtitle={group.subtitle}>
      {fieldTypeConfigs.map((groupedField, idx2) => (
        <GroupedFields key={idx2} field={groupedField} configs={configs} />
      ))}
    </GroupAccordion>
  );
}
