import { cloneDeep, merge } from "lodash";
import {
  AllBlockTypes,
  AllVersionedBlockConfigs,
  BlockConfig,
  BlockDataBase,
  BlockNode,
  BlockType,
  BlockTypeNodeMap,
  CopyableField,
  DecimalString,
  DraggableItem,
  UtilBlockType,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  VersionedBlockConfig
} from "../../../types";
import { getBlockFieldsByBlockType } from "./blockFields";
import { getLatestBlockVersion } from "./helpers";
import { getInitialState } from "./initialStates";

type PartialConfig = Partial<
  Omit<BlockConfig, "draggableItem"> & {
    draggableItem: Partial<
      Omit<DraggableItem, "data"> & {
        data?: Partial<BlockDataBase>;
      }
    >;
  }
>;

function createBlockConfigByVersion(
  type: AllBlockTypes,
  version: DecimalString,
  config: PartialConfig
) {
  return {
    [version]: merge(
      {
        fields: getBlockFieldsByBlockType(type, version),
        draggableItem: {
          type: BlockTypeNodeMap[type],
          data: { type, version, ...(cloneDeep(getInitialState(type)) || {}) }
        }
      },
      config
    )
  } as VersionedBlockConfig;
}

export const blockConfig: AllVersionedBlockConfigs = {
  [BlockType.ChatGPT]: {
    ...createBlockConfigByVersion(BlockType.ChatGPT, VERSION_1_VALUE, {
      blockPaperLabel: "Text generator",
      draggableItem: { data: { label: "LLM output" } }
    }),
    ...createBlockConfigByVersion(BlockType.ChatGPT, VERSION_2_VALUE, {
      blockPaperLabel: "Text generator",
      individualTool: true,
      draggableItem: { data: { label: "LLM output" } }
    })
  },
  [BlockType.Anthropic]: {
    ...createBlockConfigByVersion(BlockType.Anthropic, VERSION_1_VALUE, {
      blockPaperLabel: "Text generator",
      draggableItem: { data: { label: "LLM output" } }
    }),
    ...createBlockConfigByVersion(BlockType.Anthropic, VERSION_2_VALUE, {
      blockPaperLabel: "Text generator",
      individualTool: true,
      draggableItem: { data: { label: "LLM output" } }
    })
  },
  [BlockType.DallE]: {
    ...createBlockConfigByVersion(BlockType.DallE, VERSION_1_VALUE, {
      blockPaperLabel: "Image generator",
      outputType: CopyableField.Image,
      draggableItem: {
        type: BlockNode.PromptBlockNode, // V1 Dall-E uses PromptBlockNode, we should fix this
        data: { label: "Image Output Name" }
      }
    }),
    ...createBlockConfigByVersion(BlockType.DallE, VERSION_2_VALUE, {
      blockPaperLabel: "Image generator",
      individualTool: true,
      outputType: CopyableField.Image,
      draggableItem: { data: { label: "Image Output Name" } }
    })
  },
  [BlockType.Deepgram]: {
    ...createBlockConfigByVersion(BlockType.Deepgram, VERSION_1_VALUE, {
      blockPaperLabel: "Speech to text",
      draggableItem: { data: { label: "Transcript" } }
    }),
    ...createBlockConfigByVersion(BlockType.Deepgram, VERSION_2_VALUE, {
      blockPaperLabel: "Speech to text",
      individualTool: true,
      draggableItem: { data: { label: "Transcript" } }
    })
  },
  [BlockType.Scraper]: {
    ...createBlockConfigByVersion(BlockType.Scraper, VERSION_1_VALUE, {
      blockPaperLabel: "Web scraper",
      draggableItem: { data: { label: "Website content" } }
    }),
    ...createBlockConfigByVersion(BlockType.Scraper, VERSION_2_VALUE, {
      blockPaperLabel: "Web scraper",
      individualTool: true,
      draggableItem: { data: { label: "Website content" } }
    })
  },
  [BlockType.SERP]: {
    ...createBlockConfigByVersion(BlockType.SERP, VERSION_1_VALUE, {
      blockPaperLabel: "Google search",
      outputType: CopyableField.Structured,
      draggableItem: { data: { label: "Google Search Results" } }
    }),
    ...createBlockConfigByVersion(BlockType.SERP, VERSION_2_VALUE, {
      blockPaperLabel: "Google search",
      individualTool: true,
      outputType: CopyableField.Structured,
      draggableItem: { data: { label: "Google Search Results" } }
    })
  },
  [BlockType.Structured]: {
    ...createBlockConfigByVersion(BlockType.Structured, VERSION_1_VALUE, {
      blockPaperLabel: "Data extractor",
      outputType: CopyableField.Structured,
      draggableItem: {
        data: { label: "Data extractor" }
      }
    }),
    ...createBlockConfigByVersion(BlockType.Structured, VERSION_2_VALUE, {
      blockPaperLabel: "Data extractor",
      individualTool: true,
      outputType: CopyableField.Structured,
      draggableItem: {
        data: { label: "Data extractor" }
      }
    })
  },
  [BlockType.Perplexity]: {
    ...createBlockConfigByVersion(BlockType.Perplexity, VERSION_1_VALUE, {
      blockPaperLabel: "Perplexity",
      draggableItem: { data: { label: "Perplexity research" } }
    }),
    ...createBlockConfigByVersion(BlockType.Perplexity, VERSION_2_VALUE, {
      blockPaperLabel: "Perplexity",
      individualTool: true,
      draggableItem: { data: { label: "Perplexity research" } }
    })
  },
  [BlockType.IterationStart]: createBlockConfigByVersion(
    BlockType.IterationStart,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Start iteration",
      nonTestable: true,
      draggableItem: { data: { label: "Start iteration" } }
    }
  ),
  [BlockType.IterationExit]: createBlockConfigByVersion(
    BlockType.IterationExit,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Exit iteration",
      nonTestable: true,
      draggableItem: { data: { label: "Exit iteration" } }
    }
  ),
  [BlockType.Knowledge]: createBlockConfigByVersion(
    BlockType.Knowledge,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Retrieve knowledge",
      individualTool: true,
      draggableItem: { data: { label: "Retrieve knowledge" } }
    }
  ),
  [BlockType.SavetoKnowledge]: createBlockConfigByVersion(
    BlockType.SavetoKnowledge,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Save to knowledge",
      nonTestable: true,
      individualTool: true,
      draggableItem: { data: { label: "Save to knowledge" } }
    }
  ),
  [BlockType.YoutubeTranscript]: createBlockConfigByVersion(
    BlockType.YoutubeTranscript,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "YouTube transcript",
      outputType: CopyableField.Structured,
      individualTool: true,
      draggableItem: { data: { label: "YouTube transcript" } }
    }
  ),
  [BlockType.UserIntegration]: createBlockConfigByVersion(
    BlockType.UserIntegration,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Integrations",
      individualTool: true,
      draggableItem: { data: { label: "Integrations" } }
    }
  ),
  [UtilBlockType.ToolWithinTool]: createBlockConfigByVersion(
    UtilBlockType.ToolWithinTool,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Embedded Tool",
      nonTestable: true,
      draggableItem: { data: { label: "Embedded Tool" } }
    }
  ),
  [UtilBlockType.Logic]: createBlockConfigByVersion(
    UtilBlockType.Logic,
    VERSION_1_VALUE,
    {
      blockPaperLabel: "Logic",
      nonTestable: true,
      draggableItem: { data: { label: "Logic" } }
    }
  ),
  [UtilBlockType.Constant]: {
    ...createBlockConfigByVersion(UtilBlockType.Constant, VERSION_1_VALUE, {
      blockPaperLabel: "Text composer",
      nonTestable: true,
      draggableItem: { data: { label: "Text" } }
    }),
    ...createBlockConfigByVersion(UtilBlockType.Constant, VERSION_2_VALUE, {
      blockPaperLabel: "Text composer",
      nonTestable: true,
      draggableItem: { data: { label: "Text" } }
    })
  }
};

/**
 * Retrieves the block configuration for a given block type and version.
 *
 * @param {AllBlockTypes} blockType - The type of the block.
 * @param {DecimalString} [version] - The version of the block. If not provided, the latest version is used.
 * @returns {BlockConfig} The block configuration for the specified type and version.
 */
export function getRawBlockConfig(
  blockType: AllBlockTypes,
  version?: DecimalString
): BlockConfig {
  if (blockType in blockConfig) {
    if (!version) {
      version = getLatestBlockVersion(blockType);
    }
    return blockConfig[blockType][version];
  } else return {} as BlockConfig;
}
