import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import {
  type ConnectIntegrationResponse,
  type GenerateTokenResponse,
  type GetIntegrationsResponse,
  type GetComponentsResponse,
  UserIntegrationType
} from "@toolflow/shared";
import { USER_INTEGRATION_TAG_TYPE } from "../cacheTagConstants";

export const userIntegrationApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    generateToken: builder.mutation<GenerateTokenResponse, void>({
      query: () => ({
        url: `integration/token`,
        method: "POST"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setErrorMessage("Failed to generate token"));
        }
      }
    }),
    connectIntegration: builder.mutation<
      ConnectIntegrationResponse,
      { integrationType: UserIntegrationType; accountId: string }
    >({
      query: (body) => ({
        url: `integration/connect`,
        method: "POST",
        body
      }),
      invalidatesTags: [{ type: USER_INTEGRATION_TAG_TYPE }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage("Integration connected successfully"));
        } catch (err) {
          dispatch(setErrorMessage("Failed to connect integration"));
        }
      }
    }),
    getIntegrations: builder.query<GetIntegrationsResponse, void>({
      query: () => ({
        url: `integration`,
        method: "GET"
      }),
      providesTags: [{ type: USER_INTEGRATION_TAG_TYPE }]
    }),
    getComponents: builder.mutation<
      GetComponentsResponse,
      { userIntegrationType: UserIntegrationType }
    >({
      query: (body) => ({
        url: `integration/components`,
        method: "POST",
        body
      })
    }),
    getApps: builder.query<
      $TSAllowedAny,
      { query?: string; cursor?: string } | void
    >({
      query: (params) => ({
        url: `integration/apps`,
        method: "GET",
        params: params
          ? {
              q: params.query,
              after: params.cursor
            }
          : undefined
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setErrorMessage("Failed to fetch apps"));
        }
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGenerateTokenMutation,
  useConnectIntegrationMutation,
  useGetIntegrationsQuery,
  useGetComponentsMutation,
  useGetAppsQuery
} = userIntegrationApi;
