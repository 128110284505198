import { type AllBlockTypes, BlockType, UtilBlockType } from "@toolflow/shared";
import { ElementType } from "react";
import {
  ConstantIcon,
  CrawlerIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  IterationEndIcon,
  IterationStartIcon,
  KnowledgeIcon,
  LogicIcon,
  SaveToKnowledgeFillIcon,
  SpeechToTextFillIcon,
  ToolIcon,
  UserIntegrationsIcon,
  YoutubeTranscriptIcon
} from "../../../../../../globalTheme/icons/icons";
import AnthropicLogo from "../../../../../../globalTheme/icons/logos/AnthropicLogo";
import GoogleLogo from "../../../../../../globalTheme/icons/logos/GoogleLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";
import PerplexityLogo from "../../../../../../globalTheme/icons/logos/PerplexityLogo";

export const blockIcons: Record<AllBlockTypes, ElementType> = {
  [BlockType.ChatGPT]: OpenAILogo,
  [BlockType.Anthropic]: AnthropicLogo,
  [BlockType.DallE]: ImageGenerationIcon,
  [BlockType.Deepgram]: SpeechToTextFillIcon,
  [BlockType.Scraper]: CrawlerIcon,
  [BlockType.SERP]: GoogleLogo,
  [BlockType.Structured]: DataExtractorIcon,
  [BlockType.Perplexity]: PerplexityLogo,
  [BlockType.IterationStart]: IterationStartIcon,
  [BlockType.IterationExit]: IterationEndIcon,
  [BlockType.Knowledge]: KnowledgeIcon,
  [BlockType.SavetoKnowledge]: SaveToKnowledgeFillIcon,
  [BlockType.YoutubeTranscript]: YoutubeTranscriptIcon,
  [BlockType.UserIntegration]: UserIntegrationsIcon,
  [UtilBlockType.ToolWithinTool]: ToolIcon,
  [UtilBlockType.Logic]: LogicIcon,
  [UtilBlockType.Constant]: ConstantIcon
};

export function getBlockIcon(type: AllBlockTypes) {
  return blockIcons[type];
}
