import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import type { SelectFieldV1Type, SelectOption } from "@toolflow/shared";
import { isString } from "lodash";
import { useId } from "react";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useGetConfigFieldValue from "../../../helpers/useGetConfigFieldValue";

interface SelectFieldProps {
  value: string;
  fieldKey: string;
  label?: string;
  options: SelectOption[];
  disabled?: boolean;
  onChange(value: string): void;
}

function SelectFieldInner({
  value,
  fieldKey,
  options,
  label,
  disabled,
  onChange
}: SelectFieldProps) {
  const fieldId = useId();

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <InputLabel id={fieldId}>{label}</InputLabel>
      <Select
        disabled={disabled}
        labelId={fieldId}
        id={fieldKey}
        value={value}
        fullWidth
        label={label}
        onChange={(event) => onChange(event.target.value)}
        className="capitalize nowheel nodrag nopan"
      >
        {options.map((option) => {
          if (isString(option)) {
            return (
              <MenuItem value={option} key={option} className="capitalize">
                {option}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem value={option.value} key={option.value}>
                <ListItemText
                  primary={option.label}
                  secondary={option.subText}
                />
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
}

export function SelectFieldV1() {
  const { data, field, getFieldValue, updateField } =
    useFieldsByTypeContext<SelectFieldV1Type>();

  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);
  const options = useGetConfigFieldValue<SelectOption[]>(field.config.options);

  function handleOnChange(value: string) {
    updateField(value, fieldKey);
    field.onChange?.(value, data, updateField);
  }

  return (
    <SelectFieldInner
      label={field.label}
      disabled={disabled}
      fieldKey={fieldKey}
      options={options}
      value={getFieldValue(fieldKey, "")}
      onChange={handleOnChange}
    />
  );
}
