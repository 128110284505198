import {
  DYNAMIC_FIELD_PREFIX,
  DynamicFieldType,
  FieldType,
  getStringsBetweenBraces,
  isContent,
  type BlockDataBase,
  type DynamicFieldConfigBase,
  type FieldConfigBase,
  type FieldsConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import { get as getValueFromObject, isString } from "lodash";
import { useMemo } from "react";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import { getConfigFieldValue } from "../../../../common/fieldsByType/helpers/useGetConfigFieldValue";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";

export interface AvailableBlockFiled {
  field: string;
  type: FieldType;
  value: $TSAllowedAny;
}

export default function useAvailableBlockFields(
  id: string,
  fields: FieldsConfig,
  data: BlockDataBase
) {
  const getChipsList = useGetChips();
  const availableFields = useToolInputFieldsAndAvailableFields(id);

  return useMemo(() => {
    const tempFields: Set<AvailableBlockFiled> = new Set([]);

    function addAvailableField(
      value: string,
      type: FieldType,
      fieldValue: $TSAllowedAny
    ) {
      const availableField = availableFields.find(
        (field) => field.name === value || field.config?.label === value
      );

      if (availableField) {
        tempFields.add({ field: value, type, value: fieldValue });
      } else {
        const fieldsInBraces = getStringsBetweenBraces(value);
        if (!!fieldsInBraces.length) {
          fieldsInBraces.forEach((str) =>
            addAvailableField(str, type, fieldValue)
          );
        }
      }
    }

    function setTempField(
      value: string,
      type: FieldType,
      fieldValue: $TSAllowedAny
    ) {
      if (isContent(value)) {
        const chips = getChipsList(value);
        chips.forEach((chip) => {
          addAvailableField(chip, type, fieldValue);
        });
      } else if (isString(value)) {
        addAvailableField(value, type, fieldValue);
      }
    }

    function setFields(field: FieldTypeConfigs) {
      if (field.type.startsWith(DYNAMIC_FIELD_PREFIX)) {
        const fieldConfig = field.config as DynamicFieldConfigBase;
        const fieldType = getValueFromObject(data, fieldConfig.typeKey);
        if (fieldType === DynamicFieldType.Dynamic) {
          const fieldValue = getValueFromObject(data, fieldConfig.fieldKey);
          setTempField(fieldValue, field.type, fieldValue);
        }
      } else {
        const fieldConfig = field.config as FieldConfigBase;
        const fieldKey = getConfigFieldValue(fieldConfig.fieldKey, data);
        const fieldValue = getValueFromObject(data, fieldKey);
        setTempField(fieldValue, field.type, fieldValue);
      }
    }

    function setInputFieldsFromFieldsConfigs(blockFields: FieldsConfig) {
      blockFields.forEach((field) => {
        if (field.type === FieldType.Group) {
          const fc = getConfigFieldValue<FieldsConfig>(field.fields, data, []);
          fc.forEach((f) => {
            if (f.type === FieldType.Group) {
              const fc2 = getConfigFieldValue<FieldsConfig>(f.fields, data, []);
              setInputFieldsFromFieldsConfigs(fc2);
            } else {
              setFields(f);
            }
          });
        } else {
          setFields(field);
        }
      });
    }

    setInputFieldsFromFieldsConfigs(fields);

    return [...tempFields];
  }, []);
}
