import { BlockDataBase, BlockSettingsBase } from "../blocks";
import { $TSAllowedAny, UpdateSType } from "../types";
import { FieldOrCallback } from "./fieldGenericTypes";
import * as t from "./fieldsByType";

export const DYNAMIC_FIELD_PREFIX = "dynamic-";

export enum FieldType {
  SelectFieldV1 = "select-field-v1",
  SelectFieldV2 = "select-field-v2",
  JsonSchemaInput = "json-schema-input",
  PromptInputV1 = "prompt-input",
  PromptInputV2 = "prompt-input-v2",
  Slider = "slider",
  SelectTools = "select-tools",
  SelectToolForToolBuilder = "select-tools-for-tool-builder",
  WordReplaceField = "word-replace",
  SelectKnowledgeField = "select-knowledge-field",
  StaticText = "static-text",
  LogicBuilderField = "logic-builder",

  PipedreamField = "pipedream-field",
  PipedreamActionField = "pipedream-action-field",
  // Simple Fields
  SimpleInput = "simple-input",
  SimpleInputSelect = "simple-input-select",
  SimpleListCreationField = "simple-list-creation-field",
  SimpleCheckBoxField = "simple-checkbox-field",

  // Dynamic Fields
  // These start with "dynamic-" prefix.
  DynamicListField = DYNAMIC_FIELD_PREFIX + "list-field",
  DynamicInputField = DYNAMIC_FIELD_PREFIX + "input-field",
  DynamicSwitchField = DYNAMIC_FIELD_PREFIX + "switch-field",
  DynamicSelectField = DYNAMIC_FIELD_PREFIX + "select-field",
  DynamicSliderField = DYNAMIC_FIELD_PREFIX + "slider-field",

  Group = "group",

  /**
   * Used only for DeepgramV1 block
   * @deprecated
   */
  DeepgramSettings = "deepgram-settings",
  /**
   * Used only for Deepgram Block
   * @deprecated
   */
  FileField = "file-field"
}

export type UpdateField = (
  updateValue: UpdateSType,
  dataProperty: string,
  nestedArrayIndex?: number,
  nestedArrayProp?: string
) => void;

export type SelectOption =
  | string
  | { label: string; value: string; subText?: string };

export interface FieldsByTypeProps {
  configs: FieldConfig;
}

export interface BaseInputFieldProps {
  label?: string;
  subtitle?: FieldOrCallback<string>;
  disabled?: FieldOrCallback<boolean>;
  hidden?: FieldOrCallback<boolean>;
  onChange?: (
    value: $TSAllowedAny,
    data: $TSAllowedAny,
    updateField: UpdateField
  ) => void;
}

export type FieldTypeConfigs =
  | t.SelectToolsFieldType
  | t.JsonSchemaInputFieldType
  | t.SelectFieldV1Type
  | t.PromptInputV2Type
  | t.PromptInputV1Type
  | t.FileFieldType
  | t.SliderFieldType
  | t.SelectFieldV2Type
  | t.WordReplaceFieldType
  | t.SelectKnowledgeFieldType
  | t.StaticTextFieldType
  | t.SelectToolForToolBuilderFieldType
  | t.LogicBuilderFieldType
  | t.SimpleInputFieldType
  | t.SimpleCheckBoxFieldType
  | t.SimpleInputSelectFieldType
  | t.SimpleListCreationFieldType
  | t.DynamicListFieldType
  | t.DynamicSwitchFieldType
  | t.DynamicSelectFieldType
  | t.DynamicInputFieldType
  | t.DynamicSliderFieldType
  | t.PipedreamFieldType
  | t.PipedreamActionFieldType
  | t.DeepgramSettingsFieldType;

export type FieldsConfig = (FieldTypeConfigs | FieldsGroupConfig)[];

export interface FieldConfig<T = BlockSettingsBase | BlockDataBase> {
  id: string;
  data: T;
  fields: FieldsConfig;
  chipsToUse?: $TSAllowedAny;
  updateField: UpdateField;
}

export interface FieldsGroupConfig {
  type: FieldType.Group;
  label: string;
  subtitle?: string;
  fields: FieldTypeConfigs[] | ((data: $TSAllowedAny) => FieldTypeConfigs[]);
}

export enum DynamicFieldType {
  Dynamic = "dynamic",
  Preset = "preset"
}
