import { Checkbox, FormControlLabel } from "@mui/material";
import type { SimpleCheckBoxFieldType } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import useGetConfigFieldValue from "../helpers/useGetConfigFieldValue";

export default function SimpleCheckBoxField() {
  const { field, data, getFieldValue, updateField } =
    useFieldsByTypeContext<SimpleCheckBoxFieldType>();

  const label = useGetConfigFieldValue<string>(field.label);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);

  const value = getFieldValue<boolean>(fieldKey, false);

  if (hidden) {
    return null;
  }

  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      control={
        <Checkbox
          checked={value}
          disabled={disabled}
          onChange={(event) => {
            updateField(event.target.checked, fieldKey);
            field.onChange?.(event.target.checked, data, updateField);
          }}
        />
      }
    />
  );
}
