import type { AllBlockTypes, DecimalString } from "@toolflow/shared";
import { getBlockConfigFE } from "../blockConfig";

const getBlockHeaderIcon = (
  type: AllBlockTypes,
  version?: DecimalString
): React.ElementType => {
  const config = getBlockConfigFE(type, version);
  return config.icon;
};

export default getBlockHeaderIcon;
