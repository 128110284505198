import { FormControl, TextField } from "@mui/material";
import type { SimpleInputFieldType } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import useGetConfigFieldValue from "../helpers/useGetConfigFieldValue";

export default function SimpleInputField() {
  const { field, data, getFieldValue, updateField } =
    useFieldsByTypeContext<SimpleInputFieldType>();

  const label = useGetConfigFieldValue<string>(field.label);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);

  const value = getFieldValue(fieldKey, "");

  if (hidden) {
    return null;
  }

  return (
    <FormControl fullWidth margin="dense" disabled={disabled}>
      <TextField
        fullWidth
        label={label}
        margin="normal"
        value={value}
        disabled={disabled}
        onChange={(event) => {
          updateField(event.target.value, fieldKey);
          field.onChange?.(event.target.value, data, updateField);
        }}
        inputProps={field.config.inputConfig}
      />
    </FormControl>
  );
}
