import { Paper } from "@mui/material";
import type {
  JSONValue,
  UserInputDictType,
  UserInputResponseWithHandledError
} from "@toolflow/shared";
import StructuredOutput from "../../../../../tools/components/editorToolCard/outputs/structuredOutput/StructuredOutput";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import GroupAccordion from "../../../../builder/toolBuilder/common/fieldsByType/components/fieldsGroupComponent/GroupAccordion";

const ToolDetails = ({
  inputs,
  output
}: {
  inputs: UserInputDictType;
  output: UserInputResponseWithHandledError;
}) => {
  const { tool } = useToolContext();
  return (
    <GroupAccordion
      title={tool.name}
      titleVariant="subtitle2"
      titleClassName="grey"
    >
      <Paper variant="outlined" className="p-v-12px">
        <GroupAccordion title="Inputs" titleVariant="subtitle2">
          <div className="m-16px">
            <StructuredOutput value={inputs} />
          </div>
        </GroupAccordion>
        <GroupAccordion title="Outputs" titleVariant="subtitle2">
          <div className="m-16px">
            <StructuredOutput value={output as JSONValue} />
          </div>
        </GroupAccordion>
      </Paper>
    </GroupAccordion>
  );
};

export default ToolDetails;
