import { FormControl, MenuItem, Select } from "@mui/material";
import {
  getLabelFromSelectOption,
  getValueFromSelectOption,
  type TSelectInput,
  type TSetFormState,
  type UserInputDictType
} from "@toolflow/shared";
import { LabelAndInfo } from "../../../../../utilities/components/typographies/LabelAndInfo";

export const InputSelect = ({
  toolInputField,
  setFormState,
  formState,
  disabled,
  autofocus,
  disableLabelAndInfo,
  useIds
}: {
  toolInputField: TSelectInput;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  disabled?: boolean;
  autofocus?: boolean;
  disableLabelAndInfo?: boolean;
  useIds?: boolean;
}) => {
  const handleSelectChange = (e: $TSFixMe) => {
    const { name, value } = e.target;
    if (name) {
      setFormState({
        ...formState,
        [useIds ? toolInputField.id : name]: value
      });
    }
  };
  return (
    <>
      {!disableLabelAndInfo && (
        <LabelAndInfo
          name={toolInputField.name}
          info={toolInputField.description}
        />
      )}
      <FormControl fullWidth className="w-100-percent" size="small">
        <Select
          name={toolInputField.name}
          // label={toolInputField.name}
          autoFocus={autofocus}
          id={toolInputField.id}
          value={(formState[toolInputField.name] as string) || ""}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {toolInputField.options?.map((option, idx) => (
            <MenuItem key={idx} value={getValueFromSelectOption(option)}>
              {getLabelFromSelectOption(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
