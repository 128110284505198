import {
  AllBlockTypes,
  AnthropicModel,
  BlockData,
  BlockDataBase,
  BlockType,
  BlockTypeToNode,
  ConstantBlockData,
  DallEBlockDataV1,
  DallEBlockDataV2,
  DecimalString,
  DeepgramBlockDataV1,
  DeepgramBlockDataV2,
  DeepgramModel,
  DynamicFieldType,
  InputType,
  IterationExitBlockData,
  IterationStartBlockData,
  KnowledgeBlockData,
  LogicBlockData,
  OpenAiImageModel,
  OpenAiTextModel,
  PerplexityBlockDataV1,
  PerplexityBlockDataV2,
  PerplexityModel,
  SavetoKnowledgeBlockData,
  ScraperBlockDataV1,
  ScraperBlockDataV2,
  SerpBlockDataV1,
  SerpBlockDataV2,
  UserIntegrationsBlockData,
  StructuredBlockData,
  TextGenerationBlockData,
  TextGenerationV2BlockData,
  ToolWithToolBlockNotSelectedToolData,
  UtilBlockType,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  YoutubeTranscriptBlockData
} from "../../../../types";
import { getLatestBlockVersion } from "../helpers";
import { serpBlockOutputAllowedValuesDict } from "./states";

type OmittedBlockData<T> = Omit<T, keyof BlockDataBase>;
type InitialState<T extends AllBlockTypes = AllBlockTypes> = OmittedBlockData<
  BlockData<BlockTypeToNode<T>>
>;
type InitialStateRecord<T extends AllBlockTypes = AllBlockTypes> = Record<
  AllBlockTypes,
  Record<DecimalString, InitialState<T>>
>;

export const initialStates: InitialStateRecord = {
  [BlockType.ChatGPT]: {
    [VERSION_1_VALUE]: {
      prompt: "",
      optimizations: {
        llmModel: OpenAiTextModel.GPT4o,
        temperature: 50
      },
      anthropicOptimizations: {
        llmModel: AnthropicModel.OPUS,
        temperature: 50
      }
    } as OmittedBlockData<TextGenerationBlockData>,
    [VERSION_2_VALUE]: {
      settings: {
        prompt: "",
        llmModel: OpenAiTextModel.GPT4o,
        temperature: 50
      }
    } as OmittedBlockData<TextGenerationV2BlockData>
  },
  [BlockType.Anthropic]: {
    [VERSION_1_VALUE]: {
      prompt: "",
      optimizations: {
        llmModel: OpenAiTextModel.GPT4o,
        temperature: 50
      },
      anthropicOptimizations: {
        llmModel: AnthropicModel.OPUS,
        temperature: 50
      }
    } as OmittedBlockData<TextGenerationBlockData>,
    [VERSION_2_VALUE]: {
      settings: {
        prompt: "",
        llmModel: OpenAiTextModel.GPT4o,
        temperature: 50
      }
    } as OmittedBlockData<TextGenerationV2BlockData>
  },
  [BlockType.DallE]: {
    [VERSION_1_VALUE]: {
      prompt: "",
      optimizations: {
        size: "512x512",
        model: OpenAiImageModel.DALLE2,
        n: 1
      }
    } as OmittedBlockData<DallEBlockDataV1>,
    [VERSION_2_VALUE]: {
      settings: { prompt: "" },
      optimizations: {
        model: {
          type: DynamicFieldType.Preset,
          value: OpenAiImageModel.DALLE2
        },
        size: { type: DynamicFieldType.Preset, value: "512x512" },
        n: { type: DynamicFieldType.Preset, value: 1 }
      }
    } as OmittedBlockData<DallEBlockDataV2>
  },
  [BlockType.Deepgram]: {
    [VERSION_1_VALUE]: {
      settings: {
        language: "en",
        file: "",
        model: DeepgramModel.Nova2,
        smartFormat: true,
        diarize: true,
        addTimestamps: false,
        addSummary: false,
        convertMeasurementsValuesToAbbreviations: false,
        keywords: [],
        userKeywordsFieldKey: "",
        wordsToReplace: {},
        userWordsToReplaceFieldKey: "",
        isFileDictation: false,
        timeStampByEntity: "paragraph"
      }
    } as OmittedBlockData<DeepgramBlockDataV1>,
    [VERSION_2_VALUE]: {
      settings: {
        file: "",
        wordsToReplace: {},
        timeStampByEntity: {
          type: DynamicFieldType.Preset,
          value: "paragraph"
        },
        userWordsToReplaceFieldKey: "",
        model: { type: DynamicFieldType.Preset, value: DeepgramModel.Nova2 },
        language: { type: DynamicFieldType.Preset, value: "en" },
        smartFormat: { type: DynamicFieldType.Preset, value: true },
        diarize: { type: DynamicFieldType.Preset, value: true },
        addTimestamps: { type: DynamicFieldType.Preset, value: false },
        addSummary: { type: DynamicFieldType.Preset, value: false },
        convertMeasurementsValuesToAbbreviations: {
          type: DynamicFieldType.Preset,
          value: false
        },
        keywords: { type: DynamicFieldType.Preset, value: [] },
        userKeywordsFieldKey: { type: DynamicFieldType.Preset, value: "" },
        isFileDictation: { type: DynamicFieldType.Preset, value: false }
      }
    } as OmittedBlockData<DeepgramBlockDataV2>
  },
  [BlockType.IterationExit]: {
    [VERSION_1_VALUE]: {
      settings: {}
    } as OmittedBlockData<IterationExitBlockData>
  },
  [BlockType.IterationStart]: {
    [VERSION_1_VALUE]: {
      settings: {
        input: "",
        fieldType: DynamicFieldType.Dynamic
      }
    } as OmittedBlockData<IterationStartBlockData>
  },
  [BlockType.Knowledge]: {
    [VERSION_1_VALUE]: {
      settings: {
        query: "",
        count: {
          type: DynamicFieldType.Preset,
          value: 5
        },
        minScore: {
          type: DynamicFieldType.Preset,
          value: 0
        }
      }
    } as OmittedBlockData<KnowledgeBlockData>
  },
  [BlockType.Perplexity]: {
    [VERSION_1_VALUE]: {
      settings: { input: "" },
      optimizations: {
        llmModel: PerplexityModel.SonarHgOnline,
        temperature: 50
      }
    } as OmittedBlockData<PerplexityBlockDataV1>,
    [VERSION_2_VALUE]: {
      settings: { input: null },
      optimizations: {
        llmModel: {
          type: DynamicFieldType.Preset,
          value: PerplexityModel.SonarHgOnline
        },
        temperature: {
          type: DynamicFieldType.Preset,
          value: 50
        }
      }
    } as OmittedBlockData<PerplexityBlockDataV2>
  },
  [BlockType.SERP]: {
    [VERSION_1_VALUE]: {
      settings: {
        inputType: InputType.FixedInput,
        countryCode: "us",
        languageCode: "en",
        maxPagesPerQuery: 1,
        queries: "",
        userQueriesFieldKey: "",
        resultsPerPage: 10,
        allowedOutputs: serpBlockOutputAllowedValuesDict
      }
    } as OmittedBlockData<SerpBlockDataV1>,
    [VERSION_2_VALUE]: {
      settings: {
        queries: { type: DynamicFieldType.Dynamic, value: "" },
        resultsPerPage: { type: DynamicFieldType.Preset, value: 10 },
        maxPagesPerQuery: { type: DynamicFieldType.Preset, value: 1 },
        countryCode: { type: DynamicFieldType.Preset, value: "us" },
        languageCode: { type: DynamicFieldType.Preset, value: "en" }
        // allowedOutputs: getSerpAllowedOutputs(serpBlockOutputAllowedValuesDict)
      }
    } as OmittedBlockData<SerpBlockDataV2>
  },
  [BlockType.SavetoKnowledge]: {
    [VERSION_1_VALUE]: {
      settings: {
        name: { type: DynamicFieldType.Preset, value: "" },
        content: ""
      }
    } as OmittedBlockData<SavetoKnowledgeBlockData>
  },
  [BlockType.Scraper]: {
    [VERSION_1_VALUE]: {
      settings: {
        inputType: InputType.FixedInput,
        urlFieldInputKey: "",
        preferredScraperResultType: "markdown"
      }
    } as OmittedBlockData<ScraperBlockDataV1>,
    [VERSION_2_VALUE]: {
      settings: {
        url: "",
        preferredScraperResultType: {
          type: DynamicFieldType.Preset,
          value: "markdown"
        }
      }
    } as OmittedBlockData<ScraperBlockDataV2>
  },
  [BlockType.Structured]: {
    [VERSION_1_VALUE]: {
      settings: {
        input: "",
        inputType: InputType.FixedInput,
        schema: [{ type: "string", fieldLabel: "", guideline: "" }]
      },
      optimizations: {
        llmModel: "gpt-4o-2024-08-06",
        temperature: 50
      }
    } as OmittedBlockData<StructuredBlockData>
  },
  [BlockType.YoutubeTranscript]: {
    [VERSION_1_VALUE]: {
      settings: {
        url: { type: DynamicFieldType.Dynamic, value: "" },
        addTimestamp: { type: DynamicFieldType.Preset, value: false }
      }
    } as OmittedBlockData<YoutubeTranscriptBlockData>
  },
  [BlockType.UserIntegration]: {
    [VERSION_1_VALUE]: {
      settings: {
        action: "",
        configuredProps: {}
      }
    } as OmittedBlockData<UserIntegrationsBlockData>
  },
  [UtilBlockType.Constant]: {
    [VERSION_1_VALUE]: {
      constant: ""
    } as OmittedBlockData<ConstantBlockData>
  },
  [UtilBlockType.Logic]: {
    [VERSION_1_VALUE]: {
      logicArray: []
    } as OmittedBlockData<LogicBlockData>
  },
  [UtilBlockType.ToolWithinTool]: {
    [VERSION_1_VALUE]: {
      tool: null,
      inputMap: null
    } as OmittedBlockData<ToolWithToolBlockNotSelectedToolData>
  }
};

export function getInitialState<T extends AllBlockTypes = AllBlockTypes>(
  blockType: T,
  version?: DecimalString
): InitialState<T> {
  if (blockType in initialStates) {
    if (!version) {
      version = getLatestBlockVersion(blockType, initialStates);
    }
    return initialStates[blockType][version] as InitialState<T>;
  } else return {} as InitialState<T>;
}
