import type {
  BlockSettingsBase,
  FieldTypeConfigs,
  UpdateField
} from "@toolflow/shared";
import React, { createContext, useContext } from "react";
import useFieldsByTypeHelper from "./useFieldsByTypeHelper";

interface FieldsByTypeContextType<T = FieldTypeConfigs, D = BlockSettingsBase> {
  id: string;
  field: T;
  data: D;
  chipsToUse?: $TSAllowedAny;
  updateField: UpdateField;
  getFieldValue<V = $TSAllowedAny>(fieldKey: string, defaultValue: V): V;
}

interface Props {
  id: string;
  data: BlockSettingsBase;
  field: FieldTypeConfigs;
  children: React.ReactNode;
  chipsToUse?: $TSAllowedAny;
  updateField: UpdateField;
}

const FieldsByTypeContext = createContext<FieldsByTypeContextType | undefined>(
  undefined
);

export function FieldsByTypeProvider({
  id,
  data,
  field,
  children,
  chipsToUse,
  updateField
}: Readonly<Props>) {
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  return (
    <FieldsByTypeContext.Provider
      value={{
        id,
        data,
        field,
        chipsToUse,
        updateField,
        getFieldValue
      }}
    >
      {children}
    </FieldsByTypeContext.Provider>
  );
}

export function useFieldsByTypeContext<
  T = FieldTypeConfigs,
  D = BlockSettingsBase
>() {
  const context = useContext(FieldsByTypeContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsByTypeContext must be used within a FieldsByTypeProvider"
    );
  }
  return context as FieldsByTypeContextType<T, D>;
}

export default FieldsByTypeContext;
