import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import type { SimpleInputSelectFieldType } from "@toolflow/shared";
import { Fragment, useId, useState } from "react";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { TEXTFIELD } from "../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import AddInputDialog from "../../../addInput/AddInputDialog";
import useToolInputFieldsAndAvailableFields from "../../../useToolInputFieldsAndAvailableFields";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import useGetConfigFieldValue from "../helpers/useGetConfigFieldValue";

export default function SimpleInputSelectField() {
  const { id, data, field, getFieldValue, updateField } =
    useFieldsByTypeContext<SimpleInputSelectFieldType>();
  const inputTextFields = useToolInputFieldsAndAvailableFields(id);

  const inputId = useId();
  const [openInputModal, setOpenInputModal] = useState(false);

  const selectOptions = [
    ...inputTextFields
      .map((inputTextField) => ({
        label: inputTextField.name,
        value: inputTextField.name,
        type: inputTextField.type
      }))
      .filter(
        (itf) =>
          itf.type === "textField" ||
          itf.type === "tagsInput" ||
          itf.type === "blockOutput"
      )
  ];

  const label = useGetConfigFieldValue<string>(field.label);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);

  const fieldValue = getFieldValue(fieldKey, "");

  function handleOnChange(value: string) {
    updateField(value, fieldKey);
    field.onChange?.(value, data, updateField);
  }

  if (hidden) {
    return null;
  }

  return (
    <Fragment>
      <FormControl fullWidth margin="dense" disabled={disabled}>
        <InputLabel id={`${inputId}-label`}>{label}</InputLabel>
        <Select
          fullWidth
          disabled={disabled}
          labelId={`${inputId}-label`}
          id={inputId}
          label={label}
          value={fieldValue}
          className="nowheel nodrag nopan"
          onChange={(event) => {
            handleOnChange(event.target.value);
          }}
        >
          <MenuItem
            value={CREATE_USER_INPUT_LABEL}
            onClick={() => setOpenInputModal(true)}
          >
            {CREATE_USER_INPUT_LABEL}
          </MenuItem>
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={(inputField) => {
          handleOnChange(inputField.name);
        }}
        initialType={TEXTFIELD}
      />
    </Fragment>
  );
}
