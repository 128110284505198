import { FormControl, MenuItem, Select } from "@mui/material";
import {
  useFormFieldContext,
  useFrontendClient,
  useAccounts,
  useCustomize
} from "@pipedream/connect-react";

export function PipedreamAppSelect(props: $TSAllowedAny) {
  const { onChange, prop, value, extra } = useFormFieldContext();
  const client = useFrontendClient();
  const { getProps } = useCustomize();
  const app = extra?.app;

  const baseStyles = {
    gridArea: "control"
  };

  const { accounts, refetch: refetchAccounts } = useAccounts(
    {
      app: app?.name_slug,
      oauth_app_id: undefined
    },
    {
      useQueryOpts: {
        enabled: !!app
      }
    }
  );

  const startConnectAccount = async () => {
    client.connectAccount({
      app: prop.app,
      oauthAppId: undefined,
      onSuccess: async (res: { id: string }) => {
        await refetchAccounts();
        onChange({
          authProvisionId: res.id
        });
      },
      onError: () => {}
    });
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <FormControl fullWidth>
        <Select
          fullWidth
          size="small"
          value={value?.authProvisionId || ""}
          onChange={(e) => {
            if (e.target.value === "_new") {
              startConnectAccount();
            } else {
              onChange({
                authProvisionId: e.target.value
              });
            }
          }}
          className="nowheel nodrag nopan"
        >
          {accounts?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {account.img_src && (
                  <img
                    src={account.img_src}
                    alt={account.name}
                    style={{ width: 24, height: 24 }}
                  />
                )}
                {account.name}
              </div>
            </MenuItem>
          ))}
          <MenuItem value="_new">Connect new {app?.name} account...</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
