import { useEffect } from "react";
import { useBlockModalDrawer } from "../hooks/useBlockModalDrawer";
import { AllBlockTypes } from "../../../../../../../../shared/src/types/blocks";
import { Dialog } from "@mui/material";

interface BlockModalWrapperProps {
  nodeId: string;
  blockType: AllBlockTypes;
  ModalComponent: React.ComponentType<BlockModalProps>;
}

interface BlockModalProps {
  open: boolean;
  onClose: () => void;
  onActionSelect: (integration: { type: string; name: string }) => void;
}

const BlockModalWrapper = ({
  nodeId,
  blockType,
  ModalComponent
}: BlockModalWrapperProps) => {
  const { isModalOpen, openModal, closeModal, handleActionSelect } =
    useBlockModalDrawer(nodeId, blockType);

  useEffect(() => {
    openModal();
  }, []);

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          maxHeight: "90vh",
          width: "90vw",
          maxWidth: "1400px"
        }
      }}
    >
      <ModalComponent
        open={isModalOpen}
        onClose={closeModal}
        onActionSelect={handleActionSelect}
      />
    </Dialog>
  );
};

export default BlockModalWrapper;
