import {
  BlockType,
  FieldType,
  ScraperBlockDataV2,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig
} from "../../../../../types";
import { getInitialState } from "../../initialStates";

const initialState = getInitialState(BlockType.Scraper) as ScraperBlockDataV2;

export const scraperBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.PromptInputV1,
      label: "Url",
      config: {
        prependKey: "settings",
        fieldKey: "urlFieldInputKey",
        maxRows: 1,
        minRows: 1
      }
    },
    {
      type: FieldType.SelectFieldV1,
      label: "Preferred Result Type",
      config: {
        fieldKey: "settings.preferredScraperResultType",
        options: ["markdown", "html", "text"]
      }
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Url",
      subtitle: "Please type in a URL to scrape",
      config: {
        fieldKey: "settings.url",
        autofocus: true,
        placeholder: `Type "/" or click "+" for dynamic inputs`
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Preferred Result Type",
      subtitle: "Select your preferred type of results",
      config: {
        typeKey: "settings.preferredScraperResultType.type",
        fieldKey: "settings.preferredScraperResultType.value",
        options: [
          { label: "Markdown", value: "markdown" },
          { label: "HTML", value: "html" },
          { label: "Text", value: "text" }
        ],
        defaultValue: initialState.settings.preferredScraperResultType.value
      }
    }
  ]
};
