import {
  createBlockToolId,
  EntityType,
  TBlocksTagsLabels,
  TCategory,
  type TMarketplaceEntity
} from "@toolflow/shared";
import { getAllBlockConfigsFE } from "../../../../pages/builder/toolBuilder/blocks/configs/blockConfig";

export function createBlockEntities() {
  const configs = getAllBlockConfigsFE();
  return configs.reduce((acc, config) => {
    if (config.individualTool) {
      acc.push({
        description: "",
        id: createBlockToolId(config.draggableItem.data.type),
        enableForking: false,
        isFeatured: false,
        isPopular: false,
        name: config.blockPaperLabel,
        tag: {
          blocks: [TBlocksTagsLabels.OpenAI],
          categories: [TCategory.Blocks]
        },
        type: EntityType.TOOL
      } as TMarketplaceEntity);
    }
    return acc;
  }, [] as TMarketplaceEntity[]);
}
