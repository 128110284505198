import { Button } from "@mui/material";
import type { SelectToolsFieldType } from "@toolflow/shared";
import { SearchIcon } from "../../../../../../../../globalTheme/icons/icons";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import InputLabelWrapper from "../../components/InputLabelWrapper";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";
import ToolListComponent from "./inputs/ToolListComponent";

const SelectToolsField = () => {
  const { field, getFieldValue, updateField } =
    useFieldsByTypeContext<SelectToolsFieldType>();
  const { label } = field;
  const { fieldKey, className, lastUpdated } = field.config;

  const currentValue = getFieldValue<string[]>(fieldKey, []);
  const updateToolId = (value: string) => {
    if (!currentValue.includes(value)) {
      updateField([...currentValue, value], fieldKey);
    }
  };

  const updateFieldValues = (valuesList: string[]) => {
    updateField(valuesList, fieldKey);
  };

  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);

  return (
    <InputLabelWrapper
      label={label}
      subtitle={subtitle}
      className={`${className} m-b-16px`}
    >
      <div>
        <SearchEntitiesDialog
          onClickEntity={updateToolId}
          title="Add tool"
          type={SearchEntitiesDialogType.TOOLS}
          disabledEntities={{ workflows: true, agents: true }}
          button={
            <Button
              onClick={() => {}}
              startIcon={<SearchIcon size={14} />}
              disabled={disabled}
            >
              Add tool
            </Button>
          }
        />
      </div>
      <ToolListComponent
        toolIds={currentValue}
        handleChange={updateFieldValues}
        lastUpdated={lastUpdated}
      />
    </InputLabelWrapper>
  );
};

export default SelectToolsField;
