import { Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  AgentConfigFileType,
  type AgentConfigFile,
  type BlockSettingsBase,
  type SelectKnowledgeFieldType
} from "@toolflow/shared";
import {
  DeleteIcon,
  KnowledgeIcon
} from "../../../../../../../../globalTheme/icons/icons";
import RemixButton from "../../../../../../../../utilities/components/icons/RemixButton";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import InputLabelWrapper from "../../components/InputLabelWrapper";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";
import FileListItem from "./components/FileListItem";
import SearchKnowledgeDialog from "./components/SearchKnowledgeDialog";
import styles from "./selectKnowledgeField.module.css";

function SelectedFilesListComponent() {
  const { field, getFieldValue, updateField } = useFieldsByTypeContext<
    SelectKnowledgeFieldType,
    BlockSettingsBase
  >();

  const files = getFieldValue<AgentConfigFile[]>(field.config.fieldKey, []);

  const removeFile = (id: string) => {
    const filteredFiles = files.filter((file) => file.item._id !== id);
    updateField(filteredFiles, field.config.fieldKey);
  };

  if (!files.length) {
    return null;
  }

  return (
    <Grid2 container spacing={1} className={styles["file-list-container"]}>
      {files.map((file, index) => {
        return (
          <Grid2 xs={12} key={file.item._id}>
            {file.type === AgentConfigFileType.Knowledge ? (
              <div className={styles["file-list-item"]}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <KnowledgeIcon size={24} />
                  <Typography variant="body1">{file.item.name}</Typography>
                </Stack>
                <RemixButton
                  text="Delete"
                  icon={DeleteIcon}
                  onClick={() => removeFile(file.item._id)}
                  className={"tag-delete-button border-radius-6px"}
                />
              </div>
            ) : file.type === AgentConfigFileType.Uploaded ? (
              <FileListItem file={file.item} index={index} />
            ) : null}
          </Grid2>
        );
      })}
    </Grid2>
  );
}

export default function SelectKnowledgeField() {
  const { field } = useFieldsByTypeContext<
    SelectKnowledgeFieldType,
    BlockSettingsBase
  >();

  const subtitleText = useGetConfigFieldValue<string>(field.subtitle);

  return (
    <InputLabelWrapper
      label={field.label}
      subtitle={subtitleText}
      className="m-b-16px"
    >
      <SearchKnowledgeDialog />
      <SelectedFilesListComponent />
    </InputLabelWrapper>
  );
}
