import { Box, Typography } from "@mui/material";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import { type StaticTextFieldType } from "@toolflow/shared";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";

export default function StaticText() {
  const { field } = useFieldsByTypeContext<StaticTextFieldType>();
  const { label, config } = field;
  const { textAlign } = config;
  const subtitle = useGetConfigFieldValue<string>(field.subtitle);

  return (
    <Box sx={{ textAlign }}>
      {label && <Typography variant="h6">{label}</Typography>}
      {subtitle && <Typography variant="subtitleMedium">{subtitle}</Typography>}
    </Box>
  );
}
