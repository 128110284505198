import { isFunction } from "lodash";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import { type BlockDataBase } from "@toolflow/shared";

export function getConfigFieldValue<T = $TSAllowedAny>(
  field: $TSAllowedAny,
  data: BlockDataBase,
  defaultValue?: T
) {
  return ((isFunction(field) ? field(data) : field) || defaultValue) as T;
}

export default function useGetConfigFieldValue<T = $TSAllowedAny>(
  field: $TSAllowedAny,
  defaultValue?: T
) {
  const { data } = useFieldsByTypeContext<$TSAllowedAny, BlockDataBase>();
  return getConfigFieldValue(field, data, defaultValue);
}
