import { Box } from "@mui/material";
import { ReactNode, useState } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./groupAccordion.module.css";
import {
  GroupAccordionHeader,
  IGroupAccordionHeaderProps
} from "./GroupAccordionHeader";

const cx = parsedClassNames.bind(styles);

interface IGroupAccordionProps extends IGroupAccordionHeaderProps {
  animationDuration?: number;
  children: ReactNode;
}

export default function GroupAccordion({
  title,
  subtitle,
  className,
  defaultOpen = false,
  animationDuration = 300,
  children,
  titleVariant = "h8",
  titleClassName
}: IGroupAccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(className)}>
      <GroupAccordionHeader
        className={cx(styles["group-accordion-header-hover"], {
          [styles["group-accordion-header-active"]]: isOpen
        })}
        title={title}
        subtitle={subtitle}
        onClick={onClick}
        titleVariant={titleVariant}
        titleClassName={titleClassName}
        actionIcon={
          <ArrowDownSmallIcon
            className="light-grey"
            style={{ rotate: isOpen ? "180deg" : "0deg" }}
          />
        }
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: isOpen ? "1fr" : "0fr",
          transition: `grid-template-rows ${animationDuration}ms ease`
        }}
      >
        <Box sx={{ overflow: "hidden", paddingInline: 1 }}>{children}</Box>
      </Box>
    </div>
  );
}
