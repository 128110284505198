import { InputAdornment, TextField } from "@mui/material";

import { SearchIcon } from "../../../../../../globalTheme/icons/icons";

export const UserIntegrationSearchField = ({
  value,
  onChange
}: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <TextField
    size="small"
    placeholder="Search integrations..."
    fullWidth
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon size={20} />
        </InputAdornment>
      )
    }}
  />
);
