import { Stack, TextField } from "@mui/material";
import {
  isContent,
  MAX_ROWS_LARGE_TEXTFIELD,
  stringPromptFromUserInput,
  type UserInputDictType
} from "@toolflow/shared";
import { isString } from "lodash";
import { useMemo } from "react";
import InputLabelWrapper from "../../../../common/fieldsByType/components/InputLabelWrapper";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/promptInput";
import { AvailableBlockFiled } from "../hooks/useAvailableBlockFields";
import usePromptFiller from "../hooks/usePromptFiller";

function PromptPreview({
  uI,
  field: { field, value }
}: {
  field: AvailableBlockFiled;
  uI: UserInputDictType;
}) {
  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(() => {
    if (isString(value)) {
      return stringPromptFromUserInput(value, uI);
    } else if (isContent(value)) {
      return insertUserInputIntoPrompt(value, uI);
    } else return "";
  }, [uI, value, insertUserInputIntoPrompt]);

  return (
    <InputLabelWrapper label={field}>
      <TextField
        value={filledOutPrompt}
        disabled
        fullWidth
        multiline
        size="small"
        margin="normal"
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        helperText={`${filledOutPrompt.length} characters`}
      />
    </InputLabelWrapper>
  );
}

export default function TestFieldsPreviewTab({
  uI,
  fields
}: {
  uI: UserInputDictType;
  fields: AvailableBlockFiled[];
}) {
  return (
    <Stack spacing={1} className="p-h-12px">
      {fields.map((field) => (
        <PromptPreview key={field.field} field={field} uI={uI} />
      ))}
    </Stack>
  );
}
