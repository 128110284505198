import { Grid, Typography } from "@mui/material";
import type { DynamicInputFieldType, JSONSchemaItem } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";
import JsonSchemaConstructor from "./jsonSchemaConstructor/JsonSchemaConstructor";

function JsonSchemaInputField() {
  const { id, field, getFieldValue } =
    useFieldsByTypeContext<DynamicInputFieldType>();
  const {
    label,
    subtitle,
    config: { fieldKey }
  } = field;

  const schema = getFieldValue<JSONSchemaItem[]>(fieldKey, []);
  const subtitleText = useGetConfigFieldValue<string>(subtitle);

  return (
    <Grid container spacing={2} className="m-t-8px">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h8">{label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitleLarge">{subtitleText}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <JsonSchemaConstructor id={id} schema={schema} />
      </Grid>
    </Grid>
  );
}

export default JsonSchemaInputField;
