import { AllBlockTypes, BlockType } from "../../../../types";

export function createBlockToolId(type: AllBlockTypes) {
  return `${type}-block`;
}

export function getBlockTypeFromId(id: string) {
  const blockType = id.replace("-block", "") as BlockType;
  if (!Object.values(BlockType).includes(blockType)) {
    throw new Error("Invalid block id");
  }
  return blockType;
}

export function isBlockToolId(id: string) {
  return Object.values(BlockType).some((v) => id.includes(v));
}
