import {
  AnthropicModel,
  OpenAiTextModel,
  PerplexityModel,
  anthropicPriceDict,
  openAIPriceDict,
  perplexityPriceDict,
  type SelectOption,
  type TAnthropicPriceDictType,
  type TOpenAIPriceDictType,
  type TPerplexityPriceDictType
} from "../../../../types";

const formatNumberToLocale = (num: number): string => {
  try {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale).format(num);
  } catch (error) {
    console.error(`Error formatting number: ${error}`);
    // Defaulting to 'en-US' if there's an error
    return new Intl.NumberFormat("en-US").format(num);
  }
};

export function getBaseModelOptions(
  priceDict:
    | TOpenAIPriceDictType
    | TAnthropicPriceDictType
    | TPerplexityPriceDictType,
  modelFilter?: string[]
) {
  return Object.entries(priceDict).reduce((acc, [k, v]) => {
    if (!modelFilter || modelFilter.includes(k)) {
      acc.push({
        value: k,
        label: v.label,
        subText: `${formatNumberToLocale(v.tokenCount)} tokens`
      });
    }
    return acc;
  }, [] as SelectOption[]);
}

export function getTextGenerationTechOptions(modelFilter?: string[]) {
  return [
    ...getBaseModelOptions(anthropicPriceDict, modelFilter),
    ...getBaseModelOptions(openAIPriceDict, modelFilter)
  ];
}

export function getAnthropicModelOptions() {
  return getBaseModelOptions(anthropicPriceDict);
}

export function getPerplexityModelOptions() {
  return getBaseModelOptions(perplexityPriceDict, [
    PerplexityModel.SonarSmOnline,
    PerplexityModel.SonarLgOnline,
    PerplexityModel.SonarHgOnline
  ]);
}

export function getOpenAIModelOptions() {
  return getBaseModelOptions(openAIPriceDict);
}

export function getAgentModelOptions() {
  return getTextGenerationTechOptions([
    AnthropicModel.SONNET_3_5,
    OpenAiTextModel.GPT4o
  ]);
}
