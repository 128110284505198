import { useSelector } from "react-redux";
import { selectAllMarketplaceEntities } from "../../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import {
  useGetMarketplaceAgentsQuery,
  useGetMarketplaceEntitiesQuery
} from "../../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import { selectAllMarketplaceAgents } from "../../../../ToolflowAPI/rtkRoutes/selectors/agentSelectors";
import { createBlockEntities } from "../../../tools/components/searchToolsContainer/utils/getBlockTools";

export const useAllMarketplaceEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  return useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
};

export const useAllMarketplaceEntities = () => {
  useAllMarketplaceEntitiesWithLoading(); // needed to subscribe to the query
  const entities = useSelector(selectAllMarketplaceEntities);
  const blockBasedEntities = createBlockEntities(); // Add all synthetic block based tools
  return [...entities, ...blockBasedEntities];
};

export const useAllMarketplaceAgentEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  return useGetMarketplaceAgentsQuery(undefined, {
    skip: isLoading
  });
};

export const useAllMarketplaceAgentEntities = () => {
  useAllMarketplaceAgentEntitiesWithLoading(); // needed to subscribe to the query
  return useSelector(selectAllMarketplaceAgents);
};
