import { useBlockContext } from "../../../BlockContext";
import useGetDrawerNodeId from "../../../store/hooks/useGetDrawerNodeId";
import getBlockPaperLabel from "../../configs/getters/getBlockPaperLabel";
import useSetBlockMessage from "../../hooks/useSetBlockMessage";
import { useBlockIcon } from "../blockIcons/BlockIconWrapper";

const useBlockPaperHeader = () => {
  const { blockData: data, id } = useBlockContext();
  const BlockIcon = useBlockIcon(data);
  const secondaryLabel = getBlockPaperLabel(data.type, data.version);

  const { label } = data;

  useSetBlockMessage();
  const openNodeId = useGetDrawerNodeId();

  return {
    label,
    BlockIcon,
    secondaryLabel,
    isOpenNode: openNodeId === id
  };
};

export default useBlockPaperHeader;
