import type {
  BlockDataTypesWithDefault,
  DecimalString
} from "@toolflow/shared";
import { getBlockConfigFE } from "../blockConfig";

const getBlockPaperLabel = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
): string => {
  if (type === "default") {
    return "";
  }
  const config = getBlockConfigFE(type, version);
  return config.blockPaperLabel || "";
};

export default getBlockPaperLabel;
