import { useCustomize, useFormFieldContext } from "@pipedream/connect-react";
import { TextInput } from "../../fields/promptInput";
import { jsonPromptFromUserInput } from "@toolflow/shared";
import { type JSONContent } from "@tiptap/core";
import { CSSProperties } from "react";

export function PipedreamControlInput(props: $TSAllowedAny) {
  const { onChange, prop, value } = useFormFieldContext();
  console.log("Input Field", useFormFieldContext());
  const { getProps } = useCustomize();

  const baseStyles: CSSProperties = {
    gridArea: "control"
  };

  const handleTipTapChange = (jsonContent: string | JSONContent) => {
    const textContent = jsonPromptFromUserInput(jsonContent, {}, []);
    onChange(textContent);
  };

  return (
    <div {...getProps("controlBoolean", baseStyles, props)}>
      <TextInput
        id={prop?.id}
        label=""
        placeholder={prop?.label}
        initialValue={value || ""}
        handleTipTapChange={handleTipTapChange}
        className="w-100-percent"
      />
    </div>
  );
}
