import type { TStructuredAsset } from "@toolflow/shared";
import { useAssetContext } from "../../../../../../../utilities/contexts/AssetContext";
import StructuredOutput from "../../../../../../tools/components/editorToolCard/outputs/structuredOutput/StructuredOutput";
import useGetVisibleAssetValue from "../../../../hooks/useGetVisibleAssetValue";
import AssetSizeSwitch from "../assetSizeSwitch/AssetSizeSwitch";
import StructuredAssetHeader from "./StructuredAssetHeader";

export default function StructuredAsset() {
  const { asset } = useAssetContext<TStructuredAsset>();
  const value = useGetVisibleAssetValue();

  return (
    <>
      <AssetSizeSwitch header={<StructuredAssetHeader name={asset.name} />}>
        <div className="asset-max-width m-h-auto scrollable-content max-h-100-percent">
          <StructuredOutput value={value} />
        </div>
      </AssetSizeSwitch>
    </>
  );
}
