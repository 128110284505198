import { type AllBlockTypes, BlockType, UtilBlockType } from "@toolflow/shared";
import DraggableBlockContainer from "../blocks/components/draggableBlock/DraggableBlockContainer";
import styles from "./nodeSelector.module.css";

const DIVIDER = "divider";

export default function NodeSelector() {
  const blocks: (AllBlockTypes | typeof DIVIDER)[] = [
    BlockType.ChatGPT,
    BlockType.DallE,
    BlockType.Deepgram,
    BlockType.Scraper,
    BlockType.Structured,
    BlockType.Perplexity,
    DIVIDER,
    BlockType.IterationStart,
    BlockType.IterationExit,
    BlockType.SERP,
    BlockType.YoutubeTranscript,
    BlockType.Knowledge,
    BlockType.SavetoKnowledge,
    UtilBlockType.Logic,
    BlockType.UserIntegration,
    DIVIDER,
    UtilBlockType.Constant,
    UtilBlockType.ToolWithinTool
  ];
  return (
    <div className={styles.paper}>
      {blocks.map((block, idx) =>
        block === DIVIDER ? (
          <div className={styles.divider} key={idx} />
        ) : (
          <DraggableBlockContainer key={block} type={block} />
        )
      )}
    </div>
  );
}
