import Slider, { type SliderOwnProps } from "@mui/material/Slider";
import type { SliderFieldType } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import InputLabelWrapper from "../components/InputLabelWrapper";
import useGetConfigFieldValue from "../helpers/useGetConfigFieldValue";

export default function SliderField() {
  const { field, getFieldValue, updateField } =
    useFieldsByTypeContext<SliderFieldType>();
  const { config, label } = field;
  const { className } = config;

  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);
  const marksMax = useGetConfigFieldValue<number>(config.marksMax, 100);

  const onChange: SliderOwnProps["onChange"] = (_, newValue) => {
    updateField(newValue as number, fieldKey);
  };

  if (hidden) {
    return null;
  }

  return (
    <InputLabelWrapper label={label} subtitle={subtitle} className={className}>
      <Slider
        disabled={disabled}
        className="nowheel nodrag nopan"
        value={getFieldValue(fieldKey, 0)}
        onChange={onChange}
        valueLabelDisplay="auto"
        step={marksMax ? null : undefined} // If max marks exist, we want steps to be limited to the marks max array
        scale={(value) => value / 50}
        marks={Array.from({ length: marksMax + 1 }, (_, i) => ({ value: i }))}
      />
    </InputLabelWrapper>
  );
}
