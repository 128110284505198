// workspaceSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../stores/store";
import type { AllBlockTypes, OpenAPIObject } from "@toolflow/shared";

export enum DrawerEnum {
  inputs = "inputs",
  outputs = "outputs",
  node = "node",
  toolInfo = "toolInfo",
  generateSchema = "generateSchema"
}

type TGenerateSchemaDrawer = {
  type: DrawerEnum.generateSchema;
  schema: OpenAPIObject;
};

type TToolInfoDrawer = {
  type: DrawerEnum.toolInfo;
};

type TInputDrawer = {
  type: DrawerEnum.inputs;
  inputId: string;
};

type TOutputDrawer = {
  type: DrawerEnum.outputs;
  outputId: string;
};

type TNodeDrawer = {
  type: DrawerEnum.node;
  nodeId: string;
  blockType?: AllBlockTypes;
  initialData?: Record<string, $TSAllowedAny>;
};

type TDrawer =
  | TInputDrawer
  | TOutputDrawer
  | TNodeDrawer
  | TToolInfoDrawer
  | TGenerateSchemaDrawer
  | null;

interface IToolbuilderInitialState {
  drawer: TDrawer;
  drawerExpanded: boolean;
  dialogOpen: boolean;
}

const initialState: IToolbuilderInitialState = {
  drawer: null,
  drawerExpanded: false,
  dialogOpen: false
};

export type TToolbuilderReducerName = "toolbuilder";
export const TOOLBUILDER_REDUCER_NAME: TToolbuilderReducerName = "toolbuilder";

const workspaceSlice = createSlice({
  name: TOOLBUILDER_REDUCER_NAME,
  initialState,
  reducers: {
    setDrawer(state, action: PayloadAction<TDrawer>) {
      state.drawer = action.payload;
    },
    toggleDrawerExpanded(state) {
      if (state.drawer && state.drawer?.type !== DrawerEnum.toolInfo) {
        state.drawerExpanded = !state.drawerExpanded;
      }
    },
    setDrawerExpandedOpen(state, action: PayloadAction<boolean>) {
      if (state.drawer && state.drawer?.type !== DrawerEnum.toolInfo) {
        state.drawerExpanded = action.payload;
      }
    },
    setDialogOpen(state, action: PayloadAction<boolean>) {
      state.dialogOpen = action.payload;
    }
  }
});

export const selectDrawer = (state: RootState) =>
  state[TOOLBUILDER_REDUCER_NAME].drawer;

export const {
  setDrawer,
  toggleDrawerExpanded,
  setDrawerExpandedOpen,
  setDialogOpen
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
