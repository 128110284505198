import type { AllBlockTypes } from "@toolflow/shared";
import React from "react";
import { getBlockConfigFE } from "../../configs/blockConfig";
import getBlockPaperLabel from "../../configs/getters/getBlockPaperLabel";
import useParentId from "../../hooks/useParentId";
import DraggableBlock from "./DraggableBlock";
import { useDispatch } from "react-redux";
import { setDrawer, DrawerEnum } from "../../../store/toolBuilderSlice";

function DraggableBlockContainer({ type }: { type: AllBlockTypes }) {
  const { draggableItem: item, icon, modalComponent } = getBlockConfigFE(type);
  const parentId = useParentId();
  const dispatch = useDispatch();

  const onDragStart: React.DragEventHandler<HTMLDivElement> = (
    event: React.DragEvent<HTMLDivElement>
  ) => {
    const data = {
      ...item,
      parentNode: parentId,
      hasModal: !!modalComponent,
      onDrop: (nodeId: string) => {
        dispatch(
          setDrawer({
            type: DrawerEnum.node,
            nodeId,
            blockType: item.data.type,
            initialData: undefined
          })
        );
      }
    };

    event.dataTransfer.setData("application/reactflow", JSON.stringify(data));
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <DraggableBlock
      onDragStart={onDragStart}
      icon={icon}
      text={getBlockPaperLabel(item.data.type, item.data.version)}
    />
  );
}

export default DraggableBlockContainer;
