import {
  CopyableField,
  type CustomToolInputField,
  type TBlock,
  type ToolOutputOption,
  type UniqueNameOutput
} from "@toolflow/shared";
import { getOutputOptionsFnByVersion } from "../blocks/configs/blockConfigFns/fns/getOutputOptions";

function filterToolOutputOptions(toolOutputOptions: ToolOutputOption[]) {
  return toolOutputOptions.filter(
    (v, i, a) =>
      a.findIndex((t) => t.value === v.value && t.type === v.type) === i
  );
}

export const getUniqueOutputNames = (
  customToolInputFields: CustomToolInputField[],
  nodes: TBlock[]
): UniqueNameOutput => {
  const inputFieldNames = customToolInputFields
    .filter((field) => field.name && field.name.trim() !== "")
    .map((field) => {
      return {
        value: field.name,
        type: CopyableField.LargeText
      } as ToolOutputOption;
    });

  const blockFieldNames = nodes.reduce((acc, { data }) => {
    if (data.type) {
      const getOutputOptions = getOutputOptionsFnByVersion(
        data.type,
        data.version
      );
      acc.push(...getOutputOptions(data));
    }
    return acc;
  }, [] as ToolOutputOption[]);

  return {
    inputs: filterToolOutputOptions(inputFieldNames),
    blocks: filterToolOutputOptions(blockFieldNames)
  };
};
