import { type SelectFieldV2Type, type SelectOption } from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useGetConfigFieldValue from "../../../helpers/useGetConfigFieldValue";
import SelectInputInner from "../../../components/SelectInputInner";

export function SelectFieldV2() {
  const { id, field, getFieldValue } =
    useFieldsByTypeContext<SelectFieldV2Type>();

  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);
  const options = useGetConfigFieldValue<SelectOption[]>(field.config.options);

  return (
    <SelectInputInner
      id={id}
      label={field.label}
      subtitle={subtitle}
      disabled={disabled}
      options={options}
      value={getFieldValue(fieldKey, "")}
      fieldKey={fieldKey}
      callback={field.onChange}
    />
  );
}
