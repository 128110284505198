import { formatNumberToLocale } from "../../../../../functions";
import {
  AnthropicModel,
  anthropicPriceDict,
  BlockType,
  VERSION_1_VALUE,
  FieldType,
  openAIPriceDict,
  PromptBlockDataV2,
  VERSION_2_VALUE,
  type BlockFieldsConfig,
  type SelectOption
} from "../../../../../types";
import {
  getMarksMax,
  getTextGenerationTechOptions,
  isAnthropicBlock
} from "../../helpers";

export const promptBlockField: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.Group,
      label: "Model",
      fields: [
        {
          type: FieldType.SelectFieldV1,
          label: "Select text generation technology",
          config: {
            fieldKey: "type",
            options: [
              { label: "Open AI", value: "ChatGPT" },
              { label: "Anthropic", value: "Anthropic" }
            ]
          }
        },
        {
          type: FieldType.Slider,
          label: "Temperature",
          subtitle:
            "Adjust temperature of response. Higher is more random, and lower is more deterministic. Anthropic has a max temperature of 1 and OpenAI has a max temperature of 2.",
          config: {
            fieldKey(data) {
              if (isAnthropicBlock(data)) {
                return `anthropicOptimizations.temperature`;
              }
              return `optimizations.temperature`;
            },
            marksMax(data) {
              if (isAnthropicBlock(data)) {
                return 50;
              }
            },
            className: "m-v-16px"
          }
        },
        {
          type: FieldType.SelectFieldV1,
          label: "Model",
          config: {
            fieldKey(data) {
              if (isAnthropicBlock(data)) {
                return `anthropicOptimizations.llmModel`;
              }
              return `optimizations.llmModel`;
            },
            options(data) {
              const priceDict = isAnthropicBlock(data)
                ? anthropicPriceDict
                : openAIPriceDict;
              return Object.entries(priceDict).reduce((acc, [key, value]) => {
                acc.push({
                  label: key,
                  value: key,
                  subText: `${formatNumberToLocale(value.tokenCount)} tokens`
                });
                return acc;
              }, [] as SelectOption[]);
            }
          }
        }
      ]
    },
    {
      type: FieldType.Group,
      label: "Prompt",
      fields: [
        {
          type: FieldType.PromptInputV1,
          config: {
            fieldKey: "prompt"
          }
        }
      ]
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.SelectFieldV2,
      label: "Model",
      subtitle: "Select text generation technology",
      onChange(value, _, updateField) {
        if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
          updateField(BlockType.Anthropic, "type");
        } else {
          updateField(BlockType.ChatGPT, "type");
        }

        if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
          // Check if temperature is over 50 (Anthropic's max)
          updateField(50, 'settings.temperature"');
        }
      },
      config: {
        fieldKey: `settings.llmModel`,
        options: getTextGenerationTechOptions()
      }
    },
    {
      type: FieldType.PromptInputV2,
      label: "LLM Prompt",
      subtitle: "Set and preview AI instructions for response generation.",
      config: {
        fieldKey: "settings.prompt",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    },
    {
      type: FieldType.Slider,
      label: "Temperature",
      subtitle:
        "Adjust temperature of response. Higher is more random, and lower is more deterministic. Anthropic has a max temperature of 1 and OpenAI has a max temperature of 2.",
      config: {
        fieldKey: `settings.temperature`,
        marksMax: (data: PromptBlockDataV2) =>
          getMarksMax(data.settings.llmModel),
        className: "m-v-16px"
      }
    }
  ]
};
