import {
  DynamicFieldType,
  FieldType,
  OpenAiImageModel,
  VERSION_1_VALUE,
  VERSION_2_VALUE,
  type BlockFieldsConfig,
  type DallE3BlockOptimization,
  type DallEBlockDataV2,
  type FieldTypeConfigs
} from "../../../../../types";

const dallECharObject = {
  [OpenAiImageModel.DALLE2]: 1000,
  [OpenAiImageModel.DALLE3]: 4000
};

const dallE2FieldsV1: FieldTypeConfigs[] = [
  {
    type: FieldType.SelectFieldV1,
    label: "Size",
    config: {
      fieldKey: "optimizations.size",
      options: ["256x256", "512x512", "1024x1024"]
    }
  },
  {
    type: FieldType.SimpleInput,
    label: "Count",
    config: {
      fieldKey: "optimizations.n",
      inputConfig: {
        type: "number",
        min: 1,
        max: 10
      }
    }
  }
];

const dallE3FieldsV1: FieldTypeConfigs[] = [
  {
    type: FieldType.SelectFieldV1,
    label: "Size",
    config: {
      fieldKey: "optimizations.size",
      options: ["1024x1024", "1792x1024", "1024x1792"]
    }
  },
  {
    type: FieldType.SelectFieldV1,
    label: "Quality",
    config: {
      fieldKey: "optimizations.quality",
      options: ["standard", "hd"]
    }
  },
  {
    type: FieldType.SelectFieldV1,
    label: "Style",
    config: {
      fieldKey: "optimizations.style",
      options: ["vivid", "natural"]
    }
  }
];

const dallE2FieldsV2: FieldTypeConfigs[] = [
  {
    type: FieldType.DynamicSelectField,
    label: "Size",
    config: {
      typeKey: "optimizations.size.type",
      fieldKey: "optimizations.size.value",
      options: ["256x256", "512x512", "1024x1024"],
      forceDynamic(data: DallEBlockDataV2) {
        return data.optimizations.model.type === DynamicFieldType.Dynamic;
      }
    }
  },
  {
    type: FieldType.DynamicInputField,
    label: "Count",
    config: {
      typeKey: "optimizations.n.type",
      fieldKey: "optimizations.n.value",
      htmlInputType: "number",
      forceDynamic(data: DallEBlockDataV2) {
        return data.optimizations.model.type === DynamicFieldType.Dynamic;
      }
    }
  }
];

const dallE3FieldsV2: FieldTypeConfigs[] = [
  {
    type: FieldType.DynamicSelectField,
    label: "Size",
    config: {
      typeKey: "optimizations.size.type",
      fieldKey: "optimizations.size.value",
      options: ["1024x1024", "1792x1024", "1024x1792"],
      forceDynamic(data: DallEBlockDataV2) {
        return data.optimizations.model.type === DynamicFieldType.Dynamic;
      }
    }
  },
  {
    type: FieldType.DynamicSelectField,
    label: "Quality",
    config: {
      typeKey: "optimizations.quality.type",
      fieldKey: "optimizations.quality.value",
      options: [
        { label: "Standard", value: "standard" },
        { label: "HD", value: "hd" }
      ],
      forceDynamic(data: DallEBlockDataV2) {
        return data.optimizations.model.type === DynamicFieldType.Dynamic;
      }
    }
  },
  {
    type: FieldType.DynamicSelectField,
    label: "Style",
    config: {
      typeKey: "optimizations.style.type",
      fieldKey: "optimizations.style.value",
      options: [
        { label: "Vivid", value: "vivid" },
        { label: "Natural", value: "natural" }
      ],
      forceDynamic(data: DallEBlockDataV2) {
        return data.optimizations.model.type === DynamicFieldType.Dynamic;
      }
    }
  }
];

export const dallEBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.Group,
      label: "Model",
      fields(data) {
        return [
          {
            type: FieldType.SelectFieldV1,
            label: "Model",
            onChange(value: OpenAiImageModel, _, updateField) {
              if (value === OpenAiImageModel.DALLE2) {
                updateField(undefined, "optimizations.style");
                updateField(undefined, "optimizations.quality");
                updateField(1, "optimizations.size");
              } else if (value === OpenAiImageModel.DALLE3) {
                updateField(1, "optimizations.n");
                updateField("1024x1024", "optimizations.size");
                updateField("vivid", "optimizations.style");
                updateField("standard", "optimizations.quality");
              }
            },
            config: {
              fieldKey: "optimizations.model",
              options: [OpenAiImageModel.DALLE2, OpenAiImageModel.DALLE3]
            }
          },
          ...(data.optimizations.model === OpenAiImageModel.DALLE2
            ? dallE2FieldsV1
            : dallE3FieldsV1)
        ];
      }
    },
    {
      type: FieldType.Group,
      label: "Prompt",
      fields: [
        {
          type: FieldType.PromptInputV1,
          config: {
            fieldKey: "prompt"
          }
        }
      ]
    }
  ],
  [VERSION_2_VALUE]: [
    {
      type: FieldType.PromptInputV2,
      label: "Prompt",
      subtitle(data: DallEBlockDataV2) {
        const dallECharCount =
          data.optimizations.model.type === DynamicFieldType.Preset
            ? dallECharObject[data.optimizations.model.value]
            : 1000;

        return dallECharCount
          ? `Max ${dallECharCount} characters. If you are piping in a response from another prompt, you may want to summarize the response to be fewer than ${dallECharCount} characters.`
          : "";
      },
      config: {
        fieldKey: "settings.prompt",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px"
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Model",
      subtitle: "Select image generation technology.",
      config: {
        typeKey: "optimizations.model.type",
        fieldKey: "optimizations.model.value",
        options: [
          { label: "Dall-E-2", value: OpenAiImageModel.DALLE2 },
          { label: "Dall-E-3", value: OpenAiImageModel.DALLE3 }
        ]
      },
      onChange(value, data, updateField) {
        if (value === OpenAiImageModel.DALLE3) {
          const optimizations = data.optimizations as DallE3BlockOptimization;
          if (optimizations.size.type !== DynamicFieldType.Dynamic) {
            updateField("1024x1024", "optimizations.size.value");
          }

          if (optimizations.n.type !== DynamicFieldType.Dynamic) {
            updateField(1, "optimizations.n.value");
          }

          if (optimizations.quality?.type !== DynamicFieldType.Dynamic) {
            updateField(DynamicFieldType.Preset, "optimizations.quality.type");
            updateField("standard", "optimizations.quality.value");
          }

          if (optimizations.style?.type !== DynamicFieldType.Dynamic) {
            updateField(DynamicFieldType.Preset, "optimizations.style.type");
            updateField("vivid", "optimizations.style.value");
          }
        } else {
          if (data.optimizations.size.type !== DynamicFieldType.Dynamic) {
            updateField("512x512", "optimizations.size.value");
          }

          if (data.optimizations.n.type !== DynamicFieldType.Dynamic) {
            updateField(1, "optimizations.n.value");
          }
        }
      }
    },
    {
      type: FieldType.Group,
      label: "Model settings",
      fields(data: DallEBlockDataV2) {
        return data.optimizations.model.value === OpenAiImageModel.DALLE3
          ? dallE3FieldsV2
          : dallE2FieldsV2;
      }
    }
  ]
};
