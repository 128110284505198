import {
  AnthropicModel,
  getMarksMax,
  type BlockDataBase,
  type UpdateField
} from "@toolflow/shared";
import { useCallback } from "react";

interface ModelTemperatureConfig {
  temperatureFieldKey: string;
  currentModel?: string;
}

export const useModelTemperatureLogic = ({
  temperatureFieldKey,
  currentModel
}: ModelTemperatureConfig) => {
  const handleModelChange = useCallback(
    (value: string, _: BlockDataBase, updateField: UpdateField) => {
      if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
        // Check if temperature is over 50 (Anthropic's max)
        updateField(50, temperatureFieldKey);
      }
    },
    [temperatureFieldKey]
  );

  return {
    handleModelChange,
    getMarksMax: () => getMarksMax(currentModel as AnthropicModel)
  };
};
