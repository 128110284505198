import type {
  BlockDataBase,
  BlockPropsBase,
  FieldsConfig
} from "@toolflow/shared";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import FieldsByType from "../../../common/fieldsByType/FieldsByType";
import useUpdateField from "../../../useUpdateField";

interface Props<T> extends Omit<BlockPropsBase<T>, "selected"> {
  fields: FieldsConfig;
}

export default function FieldsDrawer<T extends BlockDataBase>({
  id,
  data,
  fields
}: Props<T>) {
  const updateField = useUpdateField(id);
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType configs={{ id, data, fields, chipsToUse, updateField }} />
    </div>
  );
}
