import { FormControl } from "@mui/material";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import { usePipedreamClient } from "../../../../../../../integrations/hooks/usePipeDreamClient";
import {
  FrontendClientProvider,
  ComponentFormContainer,
  CustomizeProvider
} from "@pipedream/connect-react";
import useGetCurrentUser from "../../../../../../../user/hooks/useGetCurrentUser";
import { PipedreamControlInput } from "./PipedreamInputField";
import { PipedreamAppSelect } from "./PipedreamAppSelect";
import { PipedreamBoolean } from "./PipedreamBoolean";
import { PipedreamSelectField } from "./PipedreamSelectField";
import {
  type BlockSettingsBase,
  type PipedreamFieldType
} from "@toolflow/shared";

export default function PipedreamField() {
  const { field, data, updateField } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();
  const { config } = field;
  const { fieldKey } = config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const pdClient = usePipedreamClient();
  const user = useGetCurrentUser();

  const selectedAction = getFieldValue("settings.action", "");
  const configuredProps = getFieldValue(fieldKey, {});

  const userId = user?._id;
  if (!pdClient || !selectedAction || !userId) return null;

  const handleUpdateConfiguredProps = (props: $TSAllowedAny) => {
    updateField(props, fieldKey);
    updateField(props, "settings.configuredProps");
  };

  return (
    <FormControl margin="normal" fullWidth>
      <FrontendClientProvider client={pdClient}>
        <CustomizeProvider
          components={{
            ControlInput: PipedreamControlInput,
            ControlApp: PipedreamAppSelect,
            ControlSelect: PipedreamSelectField,
            ControlBoolean: PipedreamBoolean
          }}
        >
          <ComponentFormContainer
            userId={user._id}
            componentKey={selectedAction}
            configuredProps={configuredProps}
            onUpdateConfiguredProps={handleUpdateConfiguredProps}
          />
        </CustomizeProvider>
      </FrontendClientProvider>
    </FormControl>
  );
}
