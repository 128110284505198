import { Select, MenuItem, FormControl } from "@mui/material";

export type FilterType = "all" | "connected" | "not_connected";

export const UserIntegrationFilter = ({
  value,
  onChange
}: {
  value: FilterType;
  onChange: (value: FilterType) => void;
}) => (
  <FormControl size="small" sx={{ minWidth: 200 }}>
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as FilterType)}
    >
      <MenuItem value="all">All Integrations</MenuItem>
      <MenuItem value="connected">Connected</MenuItem>
      <MenuItem value="not_connected">Not Connected</MenuItem>
    </Select>
  </FormControl>
);
