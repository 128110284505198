import { BlockNode, type ValidatedInput } from "@toolflow/shared";
import { useMemo } from "react";
import { BLOCK_OUTPUT } from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { getNestedFieldFnsByVersion } from "./blocks/configs/blockConfigFns/fns/getNestedFieldFns";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

const useToolInputFieldsAndAvailableFields = (id: string) => {
  const { state } = useToolbuilderContext();
  const { toolInputFields, availableFields, blocks, toolOutputOptions } =
    state.currentState;

  const fieldNames: ValidatedInput[] = toolInputFields;
  const fields = availableFields[id] || [];
  const blockNodesToRemove = blocks
    .filter((block) =>
      [BlockNode.LogicBlockNode, BlockNode.IterationExitBlockNode].includes(
        block.type as BlockNode
      )
    )
    .map((block) => block.data.label);

  const availableFieldsById: ValidatedInput[] = useMemo(() => {
    return fields
      .filter((field) => !blockNodesToRemove.includes(field))
      .map((field) => blocks.find((block) => block.data.label === field))
      .reduce((validatedInputs, block) => {
        if (block) {
          const { label, type, version } = block.data;
          const getNestedFields = getNestedFieldFnsByVersion(type, version);
          validatedInputs.push(
            { name: label, type: BLOCK_OUTPUT },
            ...getNestedFields(block.data, blocks)
          );
        }
        return validatedInputs;
      }, [] as ValidatedInput[]);
  }, [id, availableFields, toolOutputOptions]);

  return fieldNames.concat(availableFieldsById);
};

export default useToolInputFieldsAndAvailableFields;
