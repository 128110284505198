import {
  AnthropicModel,
  BlockType,
  TextPromptModels,
  ValidatedInput,
  type PromptBlockDataV1
} from "../../../../types";

export function getMarksMax(currentModel?: TextPromptModels) {
  return Object.values(AnthropicModel).includes(currentModel as AnthropicModel)
    ? 50
    : undefined;
}

export function isAnthropicBlock(data: PromptBlockDataV1) {
  return data.type == BlockType.Anthropic;
}

export function filterAvailableFieldsByArray(fields: ValidatedInput[]) {
  return fields.filter((field) => {
    return (
      (field.config?.valueType === "array" || field.type === "tagsInput") &&
      !field.config?.nestedOutputKey?.includes("[]")
    );
  });
}
