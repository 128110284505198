import type { SimpleListCreationFieldType } from "@toolflow/shared";
import InputListComponent from "../../../../../../tools/components/inputs/InputListComponent";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";
import useGetConfigFieldValue from "../helpers/useGetConfigFieldValue";

export default function SimpleListCreationField() {
  const { data, field, getFieldValue, updateField } =
    useFieldsByTypeContext<SimpleListCreationFieldType>();

  const label = useGetConfigFieldValue<string>(field.label);
  const hidden = useGetConfigFieldValue<boolean>(field.hidden);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);
  const fieldKey = useGetConfigFieldValue<string>(field.config.fieldKey);
  const fieldValue = getFieldValue<string[]>(fieldKey, []);

  function handleOnChange(value: string[]) {
    updateField(value, fieldKey);
    field.onChange?.(value, data, updateField);
  }

  if (hidden) {
    return null;
  }

  return (
    <div className="nowheel nodrag nopan m-t-16px">
      <InputListComponent
        label={label}
        disabled={disabled}
        valuesList={fieldValue}
        handleChange={(value) => handleOnChange(value)}
      />
    </div>
  );
}
