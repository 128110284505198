import { FormControl } from "@mui/material";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import { useFormFieldContext, useCustomize } from "@pipedream/connect-react";
import { Switch } from "../../../../builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import {
  type BlockSettingsBase,
  DynamicFieldType,
  type PipedreamFieldType
} from "@toolflow/shared";
import useUpdateField from "../../../../useUpdateField";
import { CSSProperties } from "react";

export function PipedreamBoolean(props: $TSAllowedAny) {
  const { onChange, prop, value } = useFormFieldContext();
  const { id, field, data } = useFieldsByTypeContext<
    PipedreamFieldType,
    BlockSettingsBase
  >();
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });
  const updateField = useUpdateField(id);
  const { getProps } = useCustomize();

  const baseStyles: CSSProperties = {
    width: "100%",
    minWidth: "450px",
    gridArea: "label"
  };

  const fieldKey = prop?.name || field.config.fieldKey;
  const typeKey = `${fieldKey}_type`;
  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = value || false;

  const handleChange = (newValue: string | boolean) => {
    updateField(newValue, fieldKey);
    onChange(newValue);
  };

  const handleTypeChange = (newType: DynamicFieldType) => {
    updateField(newType, typeKey);
    const defaultValue =
      newType === DynamicFieldType.Dynamic ? CREATE_USER_INPUT_LABEL : false;
    handleChange(defaultValue);
  };

  return (
    <div {...getProps("label", baseStyles, props)}>
      <FormControl margin="normal" fullWidth>
        <DynamicFieldWrapper
          id={id}
          type={dynamicFieldValue}
          label={prop?.label || ""}
          configs={{
            fieldKey,
            typeKey,
            forceDynamic: false
          }}
          fieldValue={fieldValue}
          onTypeChangeCallback={handleTypeChange}
        >
          <div className="nowheel nodrag nopan m-t-16px">
            <Switch
              checked={!!fieldValue}
              label=""
              onChange={(checked) => handleChange(checked)}
            />
          </div>
        </DynamicFieldWrapper>
      </FormControl>
    </div>
  );
}
