import {
  VERSION_1_VALUE,
  FieldType,
  type BlockFieldsConfig
} from "../../../../../types";

export const youtubeTranscriptBlockFields: BlockFieldsConfig = {
  [VERSION_1_VALUE]: [
    {
      type: FieldType.DynamicInputField,
      label: "Url",
      subtitle: "Please type in a youtube URL to scrape its transcript",
      config: {
        fieldKey: "settings.url.value",
        typeKey: "settings.url.type",
        defaultValue: "",
        placeholder: `Example: https://www.youtube.com/watch?v=***`
      }
    },
    {
      type: FieldType.DynamicSwitchField,
      label: "Add timestamp",
      subtitle: "Enable this option to add timestamps to the transcript.",
      config: {
        typeKey: "settings.addTimestamp.type",
        fieldKey: "settings.addTimestamp.value",
        defaultValue: false
      }
    }
  ]
};
