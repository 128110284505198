import { Button, Typography } from "@mui/material";
import type { LogicBlockData, LogicBuilderFieldType } from "@toolflow/shared";
import { generateInitialLogicFromToolInput } from "../../../../helpers/logicBlockHelpers";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import { LogicRow } from "./components/LogicRow";

export default function LogicBuilderField() {
  const { id, data, field, updateField } = useFieldsByTypeContext<
    LogicBuilderFieldType,
    LogicBlockData
  >();
  const toolInputFields = useToolInputFieldsAndAvailableFields(id);

  const { logicArray } = data;
  const { config } = field;
  const { fieldKey } = config;

  const addRow = () => {
    // Update the 'logicArray' field with a new logic item
    const newLogicItem = generateInitialLogicFromToolInput(toolInputFields[0]);
    if (newLogicItem) {
      updateField([...logicArray, newLogicItem], fieldKey);
    }
  };

  const deleteRow = (idx: number) => {
    // Remove the logic item at index 'idx'
    const updatedLogicArray = logicArray.filter((_, index) => index !== idx);
    updateField(updatedLogicArray, fieldKey);
  };

  return (
    <div className="flex flex-column m-16px">
      <Typography variant="subtitle1" className="m-t-8px m-b-8px">
        Connected outputs will run only if
      </Typography>
      {logicArray.map((logicItem, idx) => (
        <LogicRow
          key={idx}
          item={logicItem}
          id={id}
          idx={idx}
          deleteRow={deleteRow}
          last={idx + 1 === logicArray.length}
        />
      ))}
      {logicArray.length === 0 && (
        <Typography>
          Add a filter to determine which blocks should run
        </Typography>
      )}
      <Button onClick={addRow} className="m-t-16px m-l-auto">
        Add
      </Button>
    </div>
  );
}
