import { FormControl } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  DynamicFieldType,
  type DynamicSwitchFieldConfig,
  type DynamicSwitchFieldType
} from "@toolflow/shared";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { Switch } from "../../../../builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import DynamicFieldWrapper from "../../components/dynamicFieldWrapper/DynamicFieldWrapper";
import useGetConfigFieldValue from "../../helpers/useGetConfigFieldValue";

export default function DynamicSwitchField() {
  const { field, id, getFieldValue, updateField } =
    useFieldsByTypeContext<DynamicSwitchFieldType>();
  const { config, label } = field;
  const {
    fieldKey,
    typeKey,
    switchLabel,
    defaultValue = false,
    filterAvailableFields
  } = config as DynamicSwitchFieldConfig;

  const dynamicFieldValue = getFieldValue(typeKey, DynamicFieldType.Preset);
  const fieldValue = getFieldValue(fieldKey, defaultValue);
  const subtitle = useGetConfigFieldValue<string>(field.subtitle);
  const disabled = useGetConfigFieldValue<boolean>(field.disabled);

  return (
    <FormControl margin="normal" fullWidth disabled={disabled}>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.CHECKBOX}
        onTypeChangeCallback={(value) => {
          if (value === DynamicFieldType.Dynamic) {
            updateField(CREATE_USER_INPUT_LABEL, fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <Switch
            disabled={disabled}
            checked={fieldValue}
            label={switchLabel || "Enable this option"}
            onChange={(value) => updateField(value, fieldKey)}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
