import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import {
  AgentConfigFileType,
  type AgentConfigFile,
  type BlockSettingsBase,
  type SelectKnowledgeFieldType,
  type Snippet
} from "@toolflow/shared";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { selectAllSnippets } from "../../../../../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import {
  CheckIcon,
  CloseIcon,
  SearchIcon
} from "../../../../../../../../../globalTheme/icons/icons";
import { toolflowAppBackground } from "../../../../../../../../../globalTheme/muiUtils/appTheme";
import { RootState } from "../../../../../../../../../stores/store";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import FileFieldDropZone from "../components/FileFieldDropZone";

function SelectSnippets({
  selectedFiles,
  onSelect
}: {
  selectedFiles: AgentConfigFile[];
  onSelect?(snippet: Snippet): void;
}) {
  const snippets = useSelector((state: RootState) => selectAllSnippets(state));

  return (
    <Fragment>
      <Typography variant="h8" className="m-b-16px">
        Or select from knowledge
      </Typography>

      <List className="bg-color-white border-radius-8px">
        {snippets.map((snippet, index) => {
          return (
            <Fragment key={snippet._id}>
              <ListItem
                className="cursor-pointer"
                secondaryAction={
                  selectedFiles.some((f) => f.item._id === snippet._id) ? (
                    <div className="flex align-center">
                      <CheckIcon className="light-grey" />
                    </div>
                  ) : null
                }
              >
                <ListItemText
                  primary={snippet.name}
                  onClick={() => onSelect?.(snippet)}
                />
              </ListItem>
              {index < snippets.length - 1 && <Divider />}
            </Fragment>
          );
        })}
      </List>
    </Fragment>
  );
}

export default function SearchKnowledgeDialog({
  disabled
}: {
  disabled?: boolean;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { field, getFieldValue, updateField } = useFieldsByTypeContext<
    SelectKnowledgeFieldType,
    BlockSettingsBase
  >();

  const { fieldKey } = field.config;

  const files: AgentConfigFile[] = getFieldValue(fieldKey, []);

  const addSnippetToFiles = (snippet: Snippet) => {
    let updatedFiles: AgentConfigFile[];
    const fileAlreadySelected = files.some(
      (file) => file.item._id === snippet._id
    );

    if (fileAlreadySelected) {
      updatedFiles = files.filter((file) => file.item._id !== snippet._id);
    } else {
      updatedFiles = [
        ...files,
        { type: AgentConfigFileType.Knowledge, item: snippet }
      ];
    }

    updateField(updatedFiles, fieldKey);
    setIsDialogOpen(false);
  };

  const handleOnDrop = (droppedFiles: File[]) => {
    updateField(
      [
        ...files,
        ...droppedFiles.map((item) => ({
          type: AgentConfigFileType.Uploaded,
          item
        }))
      ],
      fieldKey
    );
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        fullWidth
        onClose={() => setIsDialogOpen(false)}
        maxWidth="xl"
        PaperProps={{
          sx: { backgroundColor: toolflowAppBackground }
        }}
      >
        <div className="pos-relative">
          <IconButton
            onClick={() => setIsDialogOpen(false)}
            className="pos-absolute"
            sx={{ top: 24, right: 24 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Container maxWidth="lg" className="m-t-32px">
              <Stack spacing={2}>
                <FileFieldDropZone
                  config={field.config}
                  onDrop={handleOnDrop}
                />
                <SelectSnippets
                  onSelect={addSnippetToFiles}
                  selectedFiles={files}
                />
              </Stack>
            </Container>
          </DialogContent>
        </div>
      </Dialog>

      <Button
        onClick={() => setIsDialogOpen((prev) => !prev)}
        startIcon={<SearchIcon size={14} />}
        disabled={disabled}
      >
        Add files
      </Button>
    </div>
  );
}
