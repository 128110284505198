export const formatNumberToLocale = (num: number): string => {
  try {
    const userLocale = navigator.language;
    return new Intl.NumberFormat(userLocale).format(num);
  } catch (error) {
    console.error(`Error formatting number: ${error}`);
    // Defaulting to 'en-US' if there's an error
    return new Intl.NumberFormat("en-US").format(num);
  }
};
