import React, { ReactElement, useState, useMemo } from "react";
import ApiKeySettings from "./ApiKeySettings";
import { useAuthorizedTier } from "../../../../billing/hooks/useAuthorizedTier";
import {
  FREE_PLAN_FE,
  ORGANIZATION_PLAN_FE
} from "../../../../billing/helpers/billingConstants";
import TeamSettings from "./TeamSettings";
import type { TAvailableTiers } from "@toolflow/shared";
import { Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import TabPanel from "../../../../../utilities/components/tabs/TabPanel";
import { a11yProps } from "../../../../../utilities/components/tabs/functions/a11yProps";
import { toolflowPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";
import {
  UserIntegrationsIcon,
  KeyIcon,
  TeamIcon,
  UserIcon
} from "../../../../../globalTheme/icons/icons";
import { getAllowedAPIKeys } from "./hooks/useAllowAPIKeys";
import useGetCurrentUser from "../../../../user/hooks/useGetCurrentUser";
import { ImpersonationSettings } from "./impersonation/ImpersonationSettings";
import IntegrationSettings from "./UserIntegrationSettings/UserIntegrationSettings";

type TTabs = "team" | "apiKeys" | "impersonation" | "userIntegrations";

type TTabValues = 0 | 1 | 2 | 3;

interface TabConfig {
  value: TTabValues;
  component: () => React.ReactNode;
  authorized?: (tier: TAvailableTiers) => boolean;
  label: string;
  icon: ReactElement;
  selectIcon: ReactElement;
  hideTab?: boolean;
}

const SettingsPageContent = () => {
  const { tab: paramTab } = useParams<{ tab?: TTabs }>();
  const authorizedTier = useAuthorizedTier();
  const currentUser = useGetCurrentUser();

  const tabDict = useMemo(() => {
    let tabId: TTabValues = 0;
    const dict: Record<TTabs, TabConfig> = {
      apiKeys: {
        label: "API Keys",
        value: tabId++ as TTabValues,
        component: ApiKeySettings,
        authorized: getAllowedAPIKeys,
        icon: <KeyIcon size={16} />,
        selectIcon: <KeyIcon size={16} color={toolflowPrimaryColor} />
      },
      team: {
        label: "Team",
        value: tabId++ as TTabValues,
        component: TeamSettings,
        authorized: (tier) => tier === ORGANIZATION_PLAN_FE,
        icon: <TeamIcon size={16} />,
        selectIcon: <TeamIcon size={16} color={toolflowPrimaryColor} />
      },
      impersonation: {
        label: "Impersonation",
        value: tabId++ as TTabValues,
        component: ImpersonationSettings,
        icon: <UserIcon size={16} />,
        selectIcon: <UserIcon size={16} color={toolflowPrimaryColor} />,
        authorized: () => currentUser?.toolflowEmployeeType === "employee",
        hideTab: currentUser?.toolflowEmployeeType !== "employee"
      },
      userIntegrations: {
        label: "Integrations",
        value: tabId++ as TTabValues,
        component: () => <IntegrationSettings />,
        authorized: (tier) => tier !== FREE_PLAN_FE,
        icon: <UserIntegrationsIcon size={16} />,
        selectIcon: (
          <UserIntegrationsIcon size={16} color={toolflowPrimaryColor} />
        )
      }
    };
    return dict;
  }, [currentUser]);

  const [value, setValue] = useState(() =>
    paramTab && tabDict[paramTab] ? tabDict[paramTab].value : 0
  );

  const handleChange = (event: React.SyntheticEvent, newValue: TTabValues) => {
    const targetTab = Object.values(tabDict).find(
      (tab) => tab.value === newValue
    );
    if (
      targetTab &&
      (!targetTab.authorized || targetTab.authorized(authorizedTier)) &&
      !targetTab.hideTab
    ) {
      setValue(newValue);
    }
  };

  const objValues = Object.values(tabDict);

  return (
    <>
      <Tabs value={value} onChange={handleChange} textColor="secondary">
        {objValues.map((tab, tabIndex) => {
          const disabled = tab.authorized && !tab.authorized(authorizedTier);

          if (tab.hideTab) return null;

          return (
            <Tab
              component="div"
              key={tab.value}
              icon={tabIndex === value ? tab.selectIcon : tab.icon}
              label={tab.label}
              disabled={disabled}
              {...a11yProps(tab.value)}
            />
          );
        })}
      </Tabs>
      {objValues.map((tab) => (
        <TabPanel
          value={value}
          key={tab.value}
          index={tab.value}
          className="w-100-percent"
        >
          {tab.component()}
        </TabPanel>
      ))}
    </>
  );
};

export default SettingsPageContent;
