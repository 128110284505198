import { useCallback, useEffect, useState } from "react";
import { CircularProgress, Box, debounce } from "@mui/material";

import { useGetAppsQuery } from "../../../../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { usePipedreamClient } from "../../../../../integrations/hooks/usePipeDreamClient";
import IntegrationCard from "../components/IntegrationCard/UserIntegrationCard";
import { UserIntegrationSearchField } from "./userIntegrationSearchField";
import { FilterType, UserIntegrationFilter } from "./userIntegrationFilter";
import styles from "./userIntegrationStyles.module.css";

interface QueryParams {
  query: string;
  cursor?: string;
}

interface UserIntegrationSettingsProps {
  onSelect?: (integration: { type: string; name: string }) => void;
}

const IntegrationsList = ({
  apps,
  onScroll,
  isLoading,
  pdClient,
  onSelect
}: {
  apps: $TSAllowedAny[];
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  isLoading: boolean;
  pdClient: $TSAllowedAny;
  onSelect?: (integration: { type: string; name: string }) => void;
}) => {
  return (
    <div className={styles["integrations-list-container"]} onScroll={onScroll}>
      {apps.map((app: $TSAllowedAny) => (
        <IntegrationCard
          key={app.id}
          integration={{
            type: app.name_slug,
            brandColor: app.brand_color,
            name: app.name,
            description: app.description,
            icon: app.img_src
          }}
          pdClient={pdClient}
          onSelect={onSelect}
        />
      ))}
      {isLoading && (
        <Box className={styles["loader-container"]}>
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
};

const UserIntegrationSettings = ({
  onSelect
}: UserIntegrationSettingsProps) => {
  const pdClient = usePipedreamClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState<FilterType>("all");
  const [allApps, setAllApps] = useState<$TSAllowedAny[]>([]);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    query: "",
    cursor: undefined
  });

  const { data, isLoading, isFetching } = useGetAppsQuery(queryParams);

  useEffect(() => {
    if (data?.data && !queryParams.cursor) {
      setAllApps(data.data);
    }
  }, [data?.data, queryParams.cursor]);

  useEffect(() => {
    if (data?.data && queryParams.cursor) {
      setAllApps((prev) => [...prev, ...data.data]);
    }
  }, [data?.data, queryParams.cursor]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setQueryParams({ query: value, cursor: undefined });
      setAllApps([]);
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      const shouldLoadMore =
        scrollHeight - scrollTop <= clientHeight * 1.5 &&
        !isLoading &&
        !isFetching &&
        data?.page_info?.end_cursor;

      if (shouldLoadMore) {
        setQueryParams((prev) => ({
          query: prev.query,
          cursor: data.page_info.end_cursor
        }));
      }
    },
    [data?.page_info?.end_cursor, isLoading, isFetching]
  );

  return (
    <div>
      <Box className="flex gap-16px m-t-16px m-b-16px">
        <UserIntegrationSearchField
          value={searchQuery}
          onChange={handleSearch}
        />
        <UserIntegrationFilter
          value={filterStatus}
          onChange={setFilterStatus}
        />
      </Box>
      <IntegrationsList
        apps={allApps}
        onScroll={handleScroll}
        isLoading={isLoading || isFetching}
        pdClient={onSelect ? undefined : pdClient}
        onSelect={onSelect}
      />
    </div>
  );
};

export default UserIntegrationSettings;
