import {
  BlockType,
  VERSION_1_VALUE,
  OutputType,
  type AllBlockTypes,
  type AvailableFieldFnRecord,
  type DecimalString,
  type DynamicFieldValue,
  type IterationStartBlockData,
  type JSONSchemaItem,
  type KnowledgeBlockData,
  type SerpBlockData,
  type StructuredBlockData,
  type TBlock,
  type TSerpAllowedOptions,
  type ValidatedInput
} from "@toolflow/shared";
import { isArray, isBoolean } from "lodash";
import { BLOCK_OUTPUT } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import {
  findNestedSchema,
  getOutputFieldsFromJsonSchema
} from "../../../../helpers/jsonSchemaHelpers";

const nestedFieldFns: AvailableFieldFnRecord = {
  [BlockType.Structured]: {
    [VERSION_1_VALUE]: ({
      label,
      settings: { schema }
    }: StructuredBlockData) => {
      return getOutputFieldsFromJsonSchema(schema).reduce((acc, cur) => {
        if (cur.path) {
          acc.push({
            name: label,
            type: BLOCK_OUTPUT,
            config: {
              type: OutputType.JsonString,
              label: `${label}.${cur.path}`,
              nestedOutputKey: cur.path,
              valueType: cur.type
            }
          });
        }

        return acc;
      }, [] as ValidatedInput[]);
    }
  },
  [BlockType.SERP]: {
    [VERSION_1_VALUE]: ({
      label,
      settings: { allowedOutputs }
    }: SerpBlockData) => {
      const allowedOutputKeys = [
        "peopleAlsoAsk",
        "relatedQueries",
        "organicResults",
        "paidResults"
      ] as (keyof TSerpAllowedOptions)[];

      return allowedOutputKeys.reduce((acc, key) => {
        const value = isBoolean(allowedOutputs[key])
          ? allowedOutputs[key]
          : (allowedOutputs[key] as DynamicFieldValue).value;

        if (!!value) {
          acc.push({
            name: label,
            type: BLOCK_OUTPUT,
            config: {
              type: OutputType.JsonObject,
              label: `${label}.${key}`,
              nestedOutputKey: key,
              valueType: "array"
            }
          });
        }
        return acc;
      }, [] as ValidatedInput[]);
    }
  },
  [BlockType.IterationStart]: {
    [VERSION_1_VALUE]: (
      { label, settings: { input } }: IterationStartBlockData,
      blocks: TBlock[]
    ) => {
      if (!isArray(input)) {
        const nestedKeys = input.replace("{{", "").replace("}}", "").split(".");
        const sourceName = nestedKeys.at(0);
        const sourceBlock = blocks.find((b) => b.data.label === sourceName);
        if (sourceBlock?.data?.type === BlockType.Structured) {
          const schema: JSONSchemaItem[] = sourceBlock.data.settings?.schema;
          if (schema) {
            const nestedPath = nestedKeys.slice(1);
            if (!!nestedPath.length) {
              const nestedValue = findNestedSchema(schema, nestedPath);
              if (
                nestedValue?.type === "array" &&
                nestedValue?.itemType.type === "object"
              ) {
                const availableJsonOutputs = getOutputFieldsFromJsonSchema(
                  nestedValue.itemType.items
                );
                return availableJsonOutputs.reduce((acc, jo) => {
                  if (jo.path) {
                    acc.push({
                      name: label,
                      type: BLOCK_OUTPUT,
                      config: {
                        type: OutputType.JsonString,
                        label: `${label}.${jo.path}`,
                        nestedOutputKey: jo.path,
                        valueType: jo.type
                      }
                    });
                  }
                  return acc;
                }, [] as ValidatedInput[]);
              }
            }
          }
        }
      }
      return [];
    }
  },
  [BlockType.Knowledge]: {
    [VERSION_1_VALUE]: ({ label }: KnowledgeBlockData) => {
      return ["files", "chunks"].map((field) => ({
        name: label,
        type: BLOCK_OUTPUT,
        config: {
          type: OutputType.JsonString,
          label: `${label}.${field}`,
          nestedOutputKey: `${label}.${field}`,
          valueType: "array"
        }
      }));
    }
  },
  [BlockType.YoutubeTranscript]: {
    [VERSION_1_VALUE]: ({ label }: KnowledgeBlockData) => {
      return [
        "title",
        "channel",
        "description",
        "duration",
        "transcript"
      ].reduce((acc, nestedField) => {
        acc.push({
          name: label,
          type: BLOCK_OUTPUT,
          config: {
            type: OutputType.JsonString,
            label: `${label}.${nestedField}`,
            nestedOutputKey: `${label}.${nestedField}`,
            valueType: "string"
          }
        });
        return acc;
      }, [] as ValidatedInput[]);
    }
  },
  default: { [VERSION_1_VALUE]: () => [] }
};

export function getNestedFieldFnsByVersion(
  blockType: AllBlockTypes | "default" = "default",
  version: DecimalString = VERSION_1_VALUE
) {
  return (
    nestedFieldFns[blockType]?.[version] ||
    nestedFieldFns[blockType]?.[VERSION_1_VALUE] ||
    nestedFieldFns.default?.[VERSION_1_VALUE]
  );
}
