import type { BlockDataTypesWithDefault } from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import getBlockHeaderIcon from "../../configs/getters/getBlockHeaderIcon";
import styles from "./blockIconWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export const useBlockIcon = (data?: BlockDataTypesWithDefault) => {
  if (!data) return null;
  const { type } = data;
  if (type === "default") {
    return null;
  }

  return getBlockHeaderIcon(type, data.version);
};

const BlockIconWrapper = ({
  BlockIcon,
  className
}: {
  BlockIcon: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx(styles["icon-with-circle"], className)}>{BlockIcon}</div>
  );
};

export default BlockIconWrapper;
