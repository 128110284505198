import {
  type PipedreamFieldType,
  UserIntegrationType,
  type UserIntegrationsBlockData,
  type GetComponentsResponse
} from "@toolflow/shared";
import { useGetComponentsMutation } from "../../../../../../../../ToolflowAPI/rtkRoutes/UserIntegrationsApi";
import { useEffect } from "react";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";

const useSlackFieldConfigs = () => {
  const { data } = useFieldsByTypeContext<
    PipedreamFieldType,
    UserIntegrationsBlockData
  >();
  const [getComponents, { data: componentsData }] = useGetComponentsMutation();

  useEffect(() => {
    if (data.settings.selectedIntegrationType) {
      getComponents({
        userIntegrationType: data.settings
          .selectedIntegrationType as UserIntegrationType
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [getComponents, data.settings.selectedIntegrationType]);

  return (
    componentsData?.data?.map(
      (component: GetComponentsResponse["data"][number]) => ({
        label: component.name,
        value: component.key
      })
    ) || []
  );
};

export default useSlackFieldConfigs;
